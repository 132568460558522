import React from 'react';
import { TextAIInfo } from '../types';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

/* =====================================================================
   Fonctions pour la génération des styles communs et calculs
===================================================================== */

/**
 * Génère les styles communs pour un élément donné.
 * @param item - L'objet représentant l'élément.
 * @returns Un objet contenant les styles communs.
 */
function generateCommonStyles(item: any): React.CSSProperties {
  return {
    position: 'absolute',
    left: item.x || 0,
    top: item.y || 0,
    opacity: item.opacity !== undefined ? item.opacity : 1,
    transform: item.rotation ? `rotate(${item.rotation}deg)` : undefined,
    display: item.visible === false ? 'none' : undefined,
  };
}

/**
 * Calcule la hauteur nécessaire pour afficher le texte dans la zone définie.
 * @param text - Le texte à afficher.
 * @param fontSize - La taille de la police.
 * @param lineHeight - La hauteur de ligne.
 * @param width - La largeur de la zone de texte.
 * @returns La hauteur calculée.
 */
function calculateHeight(text: string, fontSize: number, lineHeight: number, width: number): number {
  // Estime le nombre de caractères par ligne
  const avgCharWidth = fontSize * 0.6; // Largeur moyenne d'un caractère (~60% de la taille de police)
  const charsPerLine = Math.floor(width / avgCharWidth);

  // Calcule le nombre de lignes nécessaires
  const numberOfLines = Math.ceil(text.length / charsPerLine);

  // Retourne la hauteur totale
  return numberOfLines * (fontSize * lineHeight);
}

/* =====================================================================
   Fonctions pour la génération des composants React
===================================================================== */

/**
 * Génère un composant React en fonction du type d'élément.
 * @param item - L'objet représentant l'élément.
 * @param handleTextChange - La fonction de gestion du changement de texte.
 * @returns Un composant React correspondant à l'élément.
 */
export function generateElementComponent(item: any, handleTextChange: Function) {
  const commonStyles: React.CSSProperties = generateCommonStyles(item);

  switch (item.type) {
    case 'rect':
      return (
        <div
          key={item.id}
          id={item.id}
          style={{
            ...commonStyles,
            width: (item.width || 100) * (item.scaleX || 1),
            height: (item.height || 100) * (item.scaleY || 1),
            backgroundColor: item.fill || 'transparent',
            border: `${item.strokeWidth || 0}px solid ${item.stroke || 'transparent'}`,
            borderRadius: item.cornerRadius || 0,
            boxShadow: item.shadowBlur ? `0px 0px ${item.shadowBlur}px #000000` : undefined,
          }}
        />
      );
    case 'circle':
      const diameter = (item.radius || 50) * 2;
      const circleStyles: React.CSSProperties = {
        position: 'absolute',
        left: (item.x || 0) - (item.radius || 50),
        top: (item.y || 0) - (item.radius || 50),
        width: diameter,
        height: diameter,
        backgroundColor: item.fill || 'transparent',
        borderRadius: '50%',
        border: `${item.strokeWidth || 0}px solid ${item.stroke || 'transparent'}`,
        boxShadow: item.shadowBlur ? `0px 0px ${item.shadowBlur}px #000000` : undefined,
        opacity: item.opacity !== undefined ? item.opacity : 1,
        transform: item.rotation ? `rotate(${item.rotation}deg)` : undefined,
        display: item.visible === false ? 'none' : undefined,
      };
      return (
        <div
          key={item.id}
          id={item.id}
          style={circleStyles}
        />
      );
    case 'image':
      return (
        <img
          key={item.id}
          id={item.id}
          src={item.imageSrc}
          alt=""
          style={{
            ...commonStyles,
            width: (item.width || 100) * (item.scaleX || 1),
            height: (item.height || 100) * (item.scaleY || 1),
            objectFit: 'contain',
          }}
        />
      );
    case 'text':
      return (
        <span
          key={item.id}
          id={item.id}
          style={{
            ...commonStyles,
            fontSize: item.fontSize || 16,
            fontFamily: item.fontFamily || 'sans-serif',
            color: item.fill || '#000000',
            width: item.width || 'auto',
            fontWeight: item.fontStyle || '400',
            lineHeight: item.lineHeight || 1,
            height: item.height,
          }}
        >
          {item.text}
        </span>
      );
    case 'textAI':
      const fontSize = item.fontSize || 16;
      const lineHeight = item.lineHeight || 1.2;
      const widthTextAi = item.width || 200; // Largeur fixée
      const heightTextAi = calculateHeight(item.text || '', fontSize, lineHeight, widthTextAi);
      return (
        <textarea
          key={item.id}
          id={item.id}
          style={{
            ...commonStyles,
            fontSize: fontSize,
            fontFamily: item.fontFamily || 'sans-serif',
            color: item.fill || '#000000',
            width: widthTextAi,
            height: heightTextAi,
            lineHeight: lineHeight,
            fontWeight: item.fontStyle || '400',
            backgroundColor: 'transparent',
            border: 'none',
            resize: 'none',
            overflow: 'hidden',
          }}
          value={item.text}
          onChange={(event) => handleTextChange(event, item.id)}
          maxLength={item.maxCharacters || 1000}
        />
      );
    default:
      console.warn(`Type non supporté : ${item.type}`);
      return null;
  }
}

/* =====================================================================
   Fonctions pour la génération du HTML (pour envoi au backend)
===================================================================== */

/**
 * Génère les styles communs sous forme de chaîne pour un élément donné.
 * @param item - L'objet représentant l'élément.
 * @returns Une chaîne contenant les styles communs.
 */
function generateCommonStylesString(item: any): string {
  let style = `position: absolute; `;
  style += `left: ${item.x || 0}px; `;
  style += `top: ${item.y || 0}px; `;
  style += `opacity: ${item.opacity !== undefined ? item.opacity : 1}; `;
  if (item.rotation) {
    style += `transform: rotate(${item.rotation}deg); `;
  }
  if (item.visible === false) {
    style += `display: none; `;
  }
  return style;
}

/**
 * Génère le HTML pour un élément donné.
 * @param item - L'objet représentant l'élément.
 * @returns Une chaîne contenant le HTML de l'élément.
 */
function generateElementHTML(item: any): string {
  let style = generateCommonStylesString(item);
  let elementHtml = '';

  switch (item.type) {
    case 'rect':
      style += `width: ${(item.width || 100) * (item.scaleX || 1)}px; `;
      style += `height: ${(item.height || 100) * (item.scaleY || 1)}px; `;
      style += `background-color: ${item.fill || 'transparent'}; `;
      style += `border: ${item.strokeWidth || 0}px solid ${item.stroke || 'transparent'}; `;
      if (item.cornerRadius) {
        style += `border-radius: ${item.cornerRadius}px; `;
      }
      if (item.shadowBlur) {
        style += `box-shadow: 0px 0px ${item.shadowBlur}px #000000; `;
      }
      elementHtml = `<div id="${item.id}" style="${style}"></div>`;
      break;

    case 'circle':
      let diameter = (item.radius || 50) * 2;
      // Ajuster left et top pour centrer le cercle
      let left = (item.x || 0) - (item.radius || 50);
      let top = (item.y || 0) - (item.radius || 50);
      style = `position: absolute; `;
      style += `left: ${left}px; `;
      style += `top: ${top}px; `;
      style += `opacity: ${item.opacity !== undefined ? item.opacity : 1}; `;
      if (item.rotation) {
        style += `transform: rotate(${item.rotation}deg); `;
      }
      if (item.visible === false) {
        style += `display: none; `;
      }
      style += `width: ${diameter}px; `;
      style += `height: ${diameter}px; `;
      style += `background-color: ${item.fill || 'transparent'}; `;
      style += `border-radius: 50%; `;
      style += `border: ${item.strokeWidth || 0}px solid ${item.stroke || 'transparent'}; `;
      if (item.shadowBlur) {
        style += `box-shadow: 0px 0px ${item.shadowBlur}px #000000; `;
      }
      elementHtml = `<div id="${item.id}" style="${style}"></div>`;
      break;

    case 'image':
      let width = (item.width || 100) * (item.scaleX || 1);
      let height = (item.height || 100) * (item.scaleY || 1);
      style += `width: ${width}px; `;
      style += `height: ${height}px; `;
      style += `object-fit: contain; `;
      elementHtml = `<img id="${item.id}" src="${item.imageSrc}" style="${style}"/>`;
      break;

    case 'text':
      style += `font-size: ${item.fontSize || 16}px; `;
      style += `font-family: ${item.fontFamily || 'sans-serif'}; `;
      style += `color: ${item.fill || '#000000'}; `;
      style += `width: ${item.width || 'auto'}px; `;
      style += `font-weight: ${item.fontStyle || '400'}; `;
      style += `line-height: ${item.lineHeight || 1}; `;
      if (item.height) {
        style += `height: ${item.height}px; `;
      }
      elementHtml = `<span id="${item.id}" style="${style}">${item.text}</span>`;
      break;

    case 'textAI':
      style += `font-size: ${item.fontSize || 16}px; `;
      style += `font-family: ${item.fontFamily || 'sans-serif'}; `;
      style += `color: ${item.fill || '#000000'}; `;
      style += `width: ${item.width || 'auto'}px; `;
      style += `font-weight: ${item.fontStyle || '400'}; `;
      style += `line-height: ${item.lineHeight || 1}; `;
      if (item.height) {
        style += `height: ${item.height}px; `;
      }
      elementHtml = `<span id="${item.id}" style="${style}">${item.text}</span>`;
      break;

    default:
      console.warn(`Type non supporté : ${item.type}`);
      break;
  }

  return elementHtml;
}

/**
 * Convertit les objets Konva en chaînes HTML.
 * @param konvaObjects - Le tableau des objets Konva.
 * @returns Un tableau de chaînes HTML, une pour chaque page.
 */
export function convertKonvaObjectsToHTML(konvaObjects: any): string[] {
  let pagesHtml: string[] = []; // Liste pour stocker le HTML de chaque page

  konvaObjects.forEach((objectList: any) => {
    let html = ''; // Chaîne pour accumuler le HTML de la page actuelle

    objectList.forEach((item: any) => {
      const elementHtml = generateElementHTML(item);
      if (elementHtml) {
        html += elementHtml + '\n';
      }
    });

    pagesHtml.push(html); // Ajouter le HTML de la page à la liste des pages
  });

  return pagesHtml; // Retourner la liste des chaînes HTML
}

/* =====================================================================
   Fonctions utilitaires pour le traitement du contenu des templates
===================================================================== */

/**
 * Filtre les éléments de type 'textAI' dans les slides pour extraire les informations nécessaires.
 * @param slides - Le tableau des slides.
 * @returns Un tableau d'objets contenant les informations sur les éléments 'textAI'.
 */
export function filterTextAI(slides: any[][]): TextAIInfo[] {
  const result: TextAIInfo[] = [];
  slides.forEach((slide, slideIndex) => {
    slide.forEach((obj) => {
      if (obj.type === 'textAI') {
        result.push({
          contentType: obj.contentType,
          prePrompt: obj.prePrompt,
          slideIndex: slideIndex,     // Index de la diapositive
          maxCharacters: obj.maxCharacters,
        });
      }
    });
  });

  return result;
}

/**
 * Remplace le contenu des éléments 'textAI' avec le contenu généré.
 * @param jsonData - Les données JSON du template.
 * @param templateContent - Le contenu généré pour le template.
 * @returns Les données JSON mises à jour avec le nouveau contenu.
 */
export function replaceTextAIContent(jsonData: any, templateContent: any) {
  // Créer une copie profonde de jsonData
  const newJsonData = jsonData.map((slide: any, slideIndex: any) => {
    // Récupérer le contenu pour la diapositive correspondante dans templateContent
    const slideContent = templateContent[slideIndex];
    let contentIndex = 0;

    return slide.map((element: any) => {
      if (element.type === 'textAI' && slideContent && slideContent[contentIndex]) {
        // Retourner un nouvel objet avec le texte mis à jour
        const newElement = {
          ...element,
          text: slideContent[contentIndex].content,
          // Ne pas changer le type
        };
        contentIndex += 1;
        return newElement;
      } else {
        // Retourner l'élément tel quel
        return element;
      }
    });
  });

  return newJsonData;
}

/**
 * Génère du texte Lorem Ipsum de la longueur spécifiée.
 * @param length - La longueur souhaitée du texte.
 * @returns Une chaîne contenant le texte Lorem Ipsum.
 */
export const generateLoremIpsum = (length: number): string => {
  const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ';
  let result = '';
  while (result.length < length) {
    result += lorem;
  }
  return result.substring(0, length);
};

/* =====================================================================
   Fonctions pour la génération de PDF à partir du contenu HTML
===================================================================== */

/* Les fonctions suivantes sont utilisées pour générer un PDF à partir du contenu HTML.
   Elles ne sont pas modifiées dans ce contexte, mais vous pouvez les adapter selon vos besoins.
*/



const waitForImagesToLoad = async (container: HTMLElement): Promise<void> => {
  const images = container.querySelectorAll('img');
  const promises: Promise<void>[] = [];

  images.forEach((img) => {
    if (!img.complete) {
      promises.push(
        new Promise((resolve) => {
          img.onload = () => resolve();
          img.onerror = () => resolve();
        })
      );
    }
  });

  await Promise.all(promises);
};


const getImageAsDataURL = async (imageUrl: string): Promise<string | null> => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result as string); // Ceci sera la Data URL
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error(`Erreur lors de la conversion de l'image ${imageUrl} en Data URL:`, error);
    return null;
  }
};

const updateImageSourcesToDataURLs = async (htmlContent: string): Promise<string> => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlContent;

  const imgElements = tempDiv.querySelectorAll('img');
  const imgArray = Array.from(imgElements);

  for (const img of imgArray) {
    const originalSrc = img.getAttribute('src');

    if (originalSrc) {
      // Convertir l'image en Data URL
      const dataURL = await getImageAsDataURL(originalSrc);
      if (dataURL) {
        img.setAttribute('src', dataURL);
      } else {
        console.warn(`Impossible de convertir l'image ${originalSrc} en Data URL.`);
      }
    } else {
      console.warn(`L'image n'a pas d'attribut 'src'`);
    }
  }

  return tempDiv.innerHTML;
};

// export const generatePDFfromCarousel = async (htmlList: string[], fileName: string = 'slides.pdf') => {
//   // Dimensions exactes des slides en mm
//   const slideWidthMM = 71.4375; // 270 pixels convertis en mm
//   const slideHeightMM = 89.4083; // 338 pixels convertis en mm

//   // Créer le PDF avec des dimensions de page personnalisées
//   const pdf = new jsPDF({
//     orientation: 'portrait',
//     unit: 'mm',
//     format: [slideWidthMM, slideHeightMM] // Définir la taille de la page
//   });

//   let isFirstPage = true;

//   try {
//     for (let i = 0; i < htmlList.length; i++) {
//       let htmlContent = htmlList[i];

//       // Mettre à jour les sources d'images vers des Data URLs
//       htmlContent = await updateImageSourcesToDataURLs(htmlContent);

//       // Créer un conteneur temporaire pour rendre le contenu HTML
//       const tempContainer = document.createElement('div');
//       tempContainer.innerHTML = htmlContent;
//       tempContainer.style.position = 'absolute';
//       tempContainer.style.top = '-9999px';
//       tempContainer.style.left = '-9999px';
//       tempContainer.style.width = '270px'; // Largeur de vos slides
//       tempContainer.style.height = '338px'; // Hauteur de vos slides
//       document.body.appendChild(tempContainer);

//       // Attendre que les images soient chargées
//       await waitForImagesToLoad(tempContainer);

//       // Utiliser un scale élevé pour améliorer la résolution
//       const scale = 3; // Augmentez cette valeur pour une meilleure qualité
//       const canvas = await html2canvas(tempContainer, { backgroundColor: null, scale });

//       // Ajuster la largeur et la hauteur en fonction de l'échelle
//       const imgData = canvas.toDataURL('image/png');
//       const imgWidth = slideWidthMM * scale;
//       const imgHeight = slideHeightMM * scale;

//       if (!isFirstPage) {
//         pdf.addPage();
//       }
//       isFirstPage = false;

//       // Centrer l'image sur la page avec les dimensions originales
//       pdf.addImage(imgData, 'PNG', 0, 0, slideWidthMM, slideHeightMM);

//       // Nettoyer le DOM
//       document.body.removeChild(tempContainer);
//     }

//     // Télécharger le PDF généré
//     pdf.save(fileName);
//   } catch (error) {
//     console.error('Erreur lors de la génération du PDF:', error);
//   }
// };


