import React from 'react';
import styled from 'styled-components';
import { Layout, Switch, Dropdown, Menu, Popconfirm, message } from 'antd';
import { EditOutlined, NodeIndexOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import Toolbar from './Toolbar';
import { PostTemplateModelApi } from '../models/PostTemplateModelApi';
import { BiChevronDown } from "react-icons/bi"; // Import de l'icône DownOutlined
import AutoSizeInput from './AutoSizeInput';
import { FiSave } from "react-icons/fi";

const { Header } = Layout;

// Styled components
const RightHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  width: 338px;
  height: 100%;
`;

const HeaderCenter = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDownOutlined = styled(BiChevronDown)`
  margin-left: 8px;
  color: #fff;
  cursor: pointer;
`;



const DeleteOutlinedStyled = styled(DeleteOutlined)`
    font-size: 1.1rem;

`

const SaveOutlinedStyled = styled(SaveOutlined)`
    font-size: 1.1rem;
`

interface ButtonProps {
    active: boolean;
}

const ToolButton = styled.button<ButtonProps>`
  background-color: ${({ active }) => (active ? '#1890ff' : 'transparent')};
  color: #fff;
  border: none;
  padding: 0 10px;
  cursor: pointer;
  height: 100%;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${({ active }) => (active ? '#1890ff' : '#b0b0b0')};
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: ${({ active }) => (active ? '#1070e0' : '#a0a0a0')};
  }
`;

const StyledDownOutlinedDelete = styled(ToolButton)`
      &:hover {
        color: #ff4d4f;
    }
`;

// Props interface (adjust as necessary)
interface TemplateHeaderProps {
    action: string;
    setAction: React.Dispatch<React.SetStateAction<string>>;
    onImageUpload: (imageUrl: string, file: File) => void;
    currentTemplate: PostTemplateModelApi | null;
    handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    switchMode: boolean;
    setSwitchMode: React.Dispatch<React.SetStateAction<boolean>>;
    templates: PostTemplateModelApi[];
    handleMenuClick: (e: any) => void;
    onSave: () => Promise<{ status: boolean; message: string }>;
    onDelete: () => void;
    onNewTemplate: () => void;
    slides: any;
}

const TemplateHeader: React.FC<TemplateHeaderProps> = ({
    action,
    setAction,
    onImageUpload,
    currentTemplate,
    handleNameChange,
    switchMode,
    setSwitchMode,
    templates,
    handleMenuClick,
    onSave,
    onDelete,
    onNewTemplate,
    slides,
}) => {
    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="new" onClick={onNewTemplate}>
                + Nouveau
            </Menu.Item>
            <Menu.Divider />
            {templates.map((template) => (
                <Menu.Item key={template.id}>{template.name}</Menu.Item>
            ))}
        </Menu>
    );

    const onSaveHandle = async () => {
        const { status, message: saveMessage } = await onSave();

        console.log('status', currentTemplate);

        if (status) {
            message.success(saveMessage || 'Template saved successfully');
        } else {
            message.error(saveMessage || 'Failed to save template');
        }
    };

    return (
        <Header
            style={{
                display: 'flex',
                padding: 0,
                alignItems: 'center',
                height: '48px',
                background: '#2C2C2C',
                justifyContent: 'space-between',
            }}
        >
            <Toolbar
                action={action}
                setAction={setAction}
                onImageUpload={onImageUpload}
                currentTemplate={currentTemplate}
                onSave={onSaveHandle}
                slides={slides}
            />
            <HeaderCenter>
                <AutoSizeInput
                    value={currentTemplate?.name || ''}
                    onChange={handleNameChange}
                    placeholder="Template Name"
                    maxWidth="50ch"
                />
                <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                    <StyledDownOutlined />
                </Dropdown>
            </HeaderCenter>
            <RightHeaderContent>
                <ToolButton active={false} onClick={onSaveHandle}>
                    <SaveOutlinedStyled />
                </ToolButton>
                <Popconfirm
                    title="Delete the task"
                    description="Are you sure to delete this task?"
                    onConfirm={onDelete}
                    onCancel={() => { }}
                    okText="Yes"
                    cancelText="No"
                >
                    <StyledDownOutlinedDelete active={false}>
                        <DeleteOutlinedStyled />
                    </StyledDownOutlinedDelete>
                </Popconfirm>
                <Switch
                    checked={switchMode}
                    onChange={() => setSwitchMode(!switchMode)}
                    checkedChildren={<EditOutlined />}
                    unCheckedChildren={<NodeIndexOutlined />}
                    defaultChecked
                />
            </RightHeaderContent>
        </Header>
    );
};

export default TemplateHeader;
