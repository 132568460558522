// src/hooks/useSlides.ts
import { useState } from 'react';

const useSlides = () => {
  const [slides, setSlides] = useState<any[][]>([[]]);
  const [selectedSlideIndex, setSelectedSlideIndex] = useState<number>(0);
  const MAX_SLIDES = 10;

  const handleAddNewSlide = () => {
    setSlides((prevSlides) => {
      if (prevSlides.length < MAX_SLIDES) {
        return [...prevSlides, []];
      } else {
        console.warn("Maximum number of slides reached.");
        return prevSlides;
      }
    });

    if (slides.length < MAX_SLIDES) {
      setSelectedSlideIndex(slides.length);
    }
  };

  const handleSlideSelect = (index: number) => {
    setSelectedSlideIndex(index);
  };

  const handleRemoveSlide = (index: number) => {
    setSlides((prevSlides) => {
      const updatedSlides = prevSlides.filter((_, i) => i !== index);
      return updatedSlides;
    });

    setSelectedSlideIndex((prevIndex) => {
      if (index === prevIndex && index > 0) {
        return prevIndex - 1;
      } else if (index < prevIndex) {
        return prevIndex - 1;
      }
      return 0;
    });
  };

  const handleReorderSlides = (newSlides: any[][]) => {
    setSlides(newSlides);
  };

  return {
    slides,
    setSlides,
    selectedSlideIndex,
    setSelectedSlideIndex,
    handleAddNewSlide,
    handleSlideSelect,
    handleRemoveSlide,
    handleReorderSlides,
  };
};

export default useSlides;
