import React from 'react';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

const PersonaContainer = styled.div`
  display: flex;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: #fff;
  align-items: center;
  cursor: pointer;
  transition: background 0.1s ease;
  border: 1px solid #e4e2df;

  &:hover {
    background: #fafafa;
  }
`;

const CloseComponent = styled(CloseOutlined)`
  transition: color 0.3s;
  cursor: pointer;
  &:hover {
    color: #E64D4D;
  }
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const ItemTitle = styled.h4`
  margin: 0;
  color: #333;
  font-size: 16px;
`;

const ItemSubtitle = styled.p`
  margin: 0;
  color: #666;
  font-size: 14px;
`;

// Définir les props avec TypeScript
interface CardPostpilotProps {
  title: string; // Titre principal
  subtitle?: string; // Sous-titre optionnel
  onClose: () => void; // Fonction à appeler lorsque l'icône de fermeture est cliquée
  onClick?: () => void; // Fonction optionnelle pour gérer le clic sur le container
}

const CardPostpilot = React.forwardRef<HTMLDivElement, CardPostpilotProps>(({ title, subtitle, onClose, onClick }, ref) => {
  return (
    <PersonaContainer onClick={onClick} ref={ref}>
      <ItemDetails>
        <ItemTitle>{title}</ItemTitle>
        {subtitle && <ItemSubtitle>{subtitle}</ItemSubtitle>}
      </ItemDetails>
      <CloseComponent onClick={(e) => {
        e.stopPropagation(); // Empêche le déclenchement de onClick du container
        onClose();
      }} />
    </PersonaContainer>
  );
});

export default CardPostpilot;
