import React, { useContext, useEffect, useState } from 'react';
import { AuthForm } from '../component/AuthForm';
import { AuthContext } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const { authUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [redirectToken, setRedirectToken] = useState<string>('');
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      localStorage.setItem('jwtToken', token);
      navigate('/');
    }
  }, [navigate]);
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectToken = urlParams.get('redirect');
    if (redirectToken) {
      setRedirectToken(redirectToken);
    }
  }, []);
  
  if (authUser) {
    if (redirectToken) {
      window.location.replace('/');
    }
    window.location.replace('/');
  }
  return <AuthForm redirectToken={redirectToken} />;
};

export default LoginPage;
