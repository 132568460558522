export const ACTIONS = {
    SELECT: 'SELECT',
    RECTANGLE: 'RECTANGLE',
    CIRCLE: 'CIRCLE',
    ARROW: 'ARROW',
    SCRIBBLE: 'SCRIBBLE',
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    IMAGEAI: 'IMAGEAI',
    TEXTAI: 'TEXTAI',
  };