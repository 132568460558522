import React from 'react';
import styled from 'styled-components';
import ProductList from '../component/product/ProductList ';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  gap: 20px;
`;

export const ProductPage: React.FC = () => {
  return (
    <Container>
      <ProductList />
    </Container>
  );
};

export default ProductPage;
