const templateTips = {
    id: 'template-tips',
    title: 'Créer des templates efficaces',
    category: 'Template',
    content: `
  ## Conseils pour vos templates
  1. Utilisez un ton professionnel.
  2. Structurez vos idées avec des titres et des listes.
  3. Ajoutez des **éléments visuels** pour attirer l'attention.
  
  > *Un bon template simplifie la communication.*
  
  Exemple :
  \`\`\`markdown
  # Mon Template LinkedIn
  - Introduction captivante
  - Développement clair
  - Conclusion engageante
  \`\`\`
    `,
};

export default templateTips;
