import axios from 'axios';
import { ImageBankApi, ImagesBankApi } from '../model/ImageBankApi';

export class ImageBankService {
  private static IMAGE_BANK_BASE_URL = `${process.env.REACT_APP_API_URL}/imageBank`;
  
  static async getImageBankByKeyword(token: string, keyword: string): Promise<ImageBankApi[] | null> {
    try {
      const response = await axios.get<ImagesBankApi>(`${this.IMAGE_BANK_BASE_URL}`, {
        params: {
          'keyword': keyword,
        },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data.images!;
    } catch (error) {
      console.error('Error while fetching user posts:', error);
      throw error;
    }
  }
}