import React, { useRef, useState } from 'react';
import { Arrow, Circle, Image as KonvaImage, Layer, Line, Rect, Stage, Text, Transformer } from 'react-konva';
import { v4 as uuidv4 } from 'uuid';
import { ACTIONS } from './constants';
import styled from 'styled-components';
import Konva from 'konva';
import { generateLoremIpsum } from '../../utils/template';

interface CanvasProps {
    action: string;
    fillColor: string;
    onSelectShape: (shape: any) => void;
    onNewShape: (newShape: any) => void;
    stageRef: React.RefObject<any>;
    transformerRef: React.RefObject<any>;
    shapes: any[];
    setShapes: any;
}

const CanvasContainer = styled.div<{ cursor: string }>`
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 338px;
  background: #f5f5f5;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #C2C2C2;
  cursor: ${({ cursor }) => cursor};
`;

const StyledStage = styled(Stage)`
  width: 270px;
  height: 338px;
`;

const Canvas: React.FC<CanvasProps> = ({ stageRef, transformerRef, action, fillColor, onSelectShape, onNewShape, shapes, setShapes }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [stageSize, setStageSize] = useState({ width: 270, height: 338 });
    const [cursor, setCursor] = useState<string>('default');
    const fileInputRef = useRef<HTMLInputElement>(null);

    const isPainting = useRef<boolean>(false);
    const currentShapeId = useRef<string | null>(null);
    const startPos = useRef<{ x: number, y: number }>({ x: 0, y: 0 });

    const isDraggable = action === ACTIONS.SELECT;

    const onDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
        const node = e.target as Konva.Node;

        const updatedShape = {
            id: node.id(),
            x: node.x(),
            y: node.y(),
        };

        const newShapes = shapes.map((shape) =>
            shape.id === updatedShape.id ? { ...shape, ...updatedShape } : shape
        )

        setShapes(newShapes)

    };

    const onPointerDown = () => {
        if (action === ACTIONS.SELECT || action === ACTIONS.IMAGE) return;

        const stage = stageRef.current;
        const { x, y } = stage.getPointerPosition();
        const id = uuidv4();
        const shapeIndex = shapes.length + 1;

        currentShapeId.current = id;
        isPainting.current = true;
        startPos.current = { x, y };

        let newShape;
        let name;
        switch (action) {
            case ACTIONS.RECTANGLE:
                name = `Rectangle ${shapeIndex}`;
                newShape = {
                    id,
                    type: 'rect',
                    x,
                    y,
                    width: 20,
                    height: 20,
                    fill: fillColor,
                    isGradientFill: false,
                    name,
                    rotation: 0,
                    stroke: '#000000',
                    strokeWidth: 0,
                    shadowBlur: 0,
                    cornerRadius: 0,
                    opacity: 1,
                    visible: true,
                };
                break;
            case ACTIONS.IMAGEAI:
                name = `Image AI ${shapeIndex}`;
                newShape = {
                    id,
                    type: 'imageAI',
                    x,
                    y,
                    width: 100,
                    height: 100,
                    imageSrc: '', // Sera rempli après la génération
                    prompt: '', // Pré-prompt pour la génération d'image
                    name,
                    rotation: 0,
                    opacity: 1,
                    visible: true,
                };
                break;
            case ACTIONS.TEXTAI:
                name = `Text AI ${shapeIndex}`;
                newShape = {
                    id,
                    type: 'textAI',
                    x,
                    y,
                    text: generateLoremIpsum(100),
                    fontSize: 24,
                    fontFamily: 'Arial',
                    fill: '#000000',
                    contentType: 'title', // Par défaut
                    prePrompt: '', // Pré-prompt par défaut
                    maxCharacters: 100,
                    lineHeight: 1,
                    name,
                    rotation: 0,
                    opacity: 1,
                    visible: true,
                    width: 200,
                };
                break;
            case ACTIONS.CIRCLE:
                name = `Circle ${shapeIndex}`;
                newShape = {
                    id,
                    type: 'circle',
                    x,
                    y,
                    radius: 10,
                    fill: fillColor,
                    isGradientFill: false,
                    name,
                    rotation: 0,
                    stroke: '#000000',
                    strokeWidth: 0,
                    shadowBlur: 0,
                    opacity: 1,
                    visible: true,
                };
                break;
            case ACTIONS.ARROW:
                name = `Arrow ${shapeIndex}`;
                newShape = {
                    id,
                    type: 'arrow',
                    x,
                    y,
                    points: [x, y, x, y],
                    fill: fillColor,
                    isGradientFill: false,
                    name,
                    rotation: 0,
                    stroke: '#000000',
                    strokeWidth: 2,  // Typiquement, une flèche aurait un contour, donc 2px par défaut
                    shadowBlur: 0,
                    opacity: 1,
                    visible: true,
                };
                break;
            case ACTIONS.SCRIBBLE:
                name = `Scribble ${shapeIndex}`;
                newShape = {
                    id,
                    type: 'scribble',
                    x,
                    y,
                    points: [x, y],
                    fill: fillColor,
                    isGradientFill: false,
                    name,
                    rotation: 0,
                    stroke: '#000000',
                    strokeWidth: 2,  // Griffonnage avec un contour visible
                    shadowBlur: 0,
                    opacity: 1,
                    visible: true,
                };
                break;
            case ACTIONS.TEXT:
                name = `Text ${shapeIndex}`;
                newShape = {
                    id,
                    type: 'text',
                    x,
                    y,
                    text: 'Double-click to edit',
                    fontSize: 24,
                    fontFamily: 'Calibri',
                    fill: "#000000",
                    isGradientFill: false,
                    name,
                    rotation: 0,
                    stroke: '#000000',
                    strokeWidth: 0,
                    shadowBlur: 0,
                    opacity: 1,
                    visible: true,
                    width: 200, // Définissez une largeur maximale
                    wrap: 'word', // Définissez la méthode de retour à la ligne
                    lineHeight: 1,
                };
                break;
            case ACTIONS.IMAGE:
                if (fileInputRef.current) {
                    fileInputRef.current.click();
                }
                break;
        }
        if (newShape) {
            // setShapes([...shapes, newShape]);
            onNewShape(newShape);
        }
    };

    const onPointerMove = () => {
        if (action === ACTIONS.SELECT || !isPainting.current) return;

        const stage = stageRef.current;
        const { x, y } = stage.getPointerPosition();
        const startX = startPos.current.x;
        const startY = startPos.current.y;


        setShapes(shapes.map((shape) => {
            if (shape.id !== currentShapeId.current) return shape;

            switch (shape.type) {
                case 'rect':
                    return {
                        ...shape,
                        x: Math.min(x, startX),
                        y: Math.min(y, startY),
                        width: Math.abs(x - startX),
                        height: Math.abs(y - startY),
                    };
                case 'circle':
                    return {
                        ...shape,
                        radius: Math.sqrt((x - startX) ** 2 + (y - startY) ** 2),
                    };
                case 'arrow':
                    return {
                        ...shape,
                        points: [startX, startY, x, y],
                    };
                case 'scribble':
                    return {
                        ...shape,
                        points: [...shape.points, x, y],
                    };
                case 'text':
                    return {
                        ...shape,
                        x: x,
                        y: y,
                    };
                default:
                    return shape;
            }
        }));
    };

    const onPointerUp = () => {
        isPainting.current = false;
    };

    const onClick = (e: any) => {
        e.cancelBubble = true;
        const target = e.target;
        onSelectShape(target);

        transformerRef.current.nodes([target]);
        transformerRef.current.getLayer().batchDraw();
    };

    const onDoubleClick = (e: any) => {
        const target = e.target;

        // if (target.attrs.type === 'textAI') {
        //     return;
        // }

        if (target instanceof Konva.Text) {
            const textPosition = target.getAbsolutePosition();
            const stageBox = stageRef.current.container().getBoundingClientRect();

            const areaPosition = {
                x: stageBox.left + textPosition.x,
                y: stageBox.top + textPosition.y,
            };

            const textarea = document.createElement('textarea');
            document.body.appendChild(textarea);

            textarea.value = target.text();
            textarea.style.position = 'absolute';
            textarea.style.top = areaPosition.y + 'px';
            textarea.style.left = areaPosition.x + 'px';
            textarea.style.width = target.width() - target.padding() * 2 + 'px';
            textarea.style.fontSize = target.fontSize() + 'px';
            textarea.style.border = 'none';
            textarea.style.padding = '0px';
            textarea.style.margin = '0px';
            textarea.style.overflow = 'hidden';
            textarea.style.background = 'none';
            textarea.style.outline = 'none';
            textarea.style.resize = 'none';
            textarea.style.lineHeight = target.lineHeight().toString();
            textarea.style.fontFamily = target.fontFamily();
            textarea.style.transformOrigin = 'left top';
            textarea.style.textAlign = target.align();
            textarea.style.color = target.fill() as string;
            textarea.style.height = 'auto';
            textarea.style.display = 'block';
            textarea.style.zIndex = '1000';
            textarea.style.whiteSpace = 'pre-wrap';

            textarea.focus();

            target.hide();
            const layer = target.getLayer();
            if (layer) {
                layer.batchDraw();
            }

            const updateText = () => {
                const newText = textarea.value;
                target.text(newText);

                if (layer) {
                    layer.batchDraw();
                }

                // Update the shapes state with the new text
                const updatedShapes = shapes.map(shape =>
                    shape.id === target.id()
                        ? { ...shape, text: newText }
                        : shape
                );
                setShapes(updatedShapes);
            };

            const removeTextarea = () => {
                updateText();
                textarea.parentNode?.removeChild(textarea);
                window.removeEventListener('click', handleOutsideClick);
                target.show();
                if (layer) {
                    layer.batchDraw();
                }
            };

            const setTextareaWidth = (newWidth: any) => {
                if (!newWidth) {
                    newWidth = target.text().length * target.fontSize();
                }

                const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                if (isSafari) {
                    newWidth = Math.ceil(newWidth);
                }

                const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                if (isFirefox) {
                    newWidth += 2;
                }

                textarea.style.width = newWidth + 'px';
            };

            textarea.addEventListener('keydown', (e: any) => {
                if (e.key === 'Enter' && !e.shiftKey) { // Permet d'ajouter une nouvelle ligne avec Shift + Enter
                    e.preventDefault();
                    updateText();
                    removeTextarea();
                } else if (e.key === 'Escape') {
                    removeTextarea();
                }
            });

            textarea.addEventListener('keyup', (e: any) => {
                setTextareaWidth(e.target.scrollWidth);
            });

            const handleOutsideClick = (e: any) => {
                if (e.target !== textarea) {
                    removeTextarea();
                }
            };

            setTimeout(() => {
                window.addEventListener('click', handleOutsideClick);
            });
        }
    };


    const deselectShape = () => {
        transformerRef.current.nodes([]);
        transformerRef.current.getLayer().batchDraw();
        onSelectShape(null);
    };

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = () => {
            const imageObj = new window.Image();
            imageObj.onload = () => {
                const newImage = {
                    id: uuidv4(),
                    type: 'image',
                    x: stageSize.width / 2 - 50,
                    y: stageSize.height / 2 - 50,
                    image: imageObj,
                    width: 100,
                    height: 100,
                    isSavedToBackend: false,
                };
                setShapes([...shapes, newImage]);
                onNewShape(newImage);
            };
            imageObj.src = reader.result as string;
        };
        reader.readAsDataURL(file);
    };

    const onTransformEnd = (e: Konva.KonvaEventObject<Event>) => {
        const node = e.target as Konva.Node;
    
        const updatedShape = {
            id: node.id(),
            x: node.x(),
            y: node.y(),
            width: node.width(),
            height: node.height(),
            rotation: node.rotation(),
            scaleX: node.scaleX(),
            scaleY: node.scaleY(),
        };
    
        // Reset scale to avoid doubling the effect
        node.scaleX(1);
        node.scaleY(1);
    
        // Met à jour les formes dans l'état avec les transformations appliquées
        const newShapes = shapes.map((shape) =>
            shape.id === updatedShape.id ? { ...shape, ...updatedShape } : shape
        );
    
        setShapes(newShapes);
    };

    const onTransformEndText = (e: Konva.KonvaEventObject<Event>) => {
        const node = e.target as Konva.Text;
    
        let updatedShape = {
            id: node.id(),
            x: node.x(),
            y: node.y(),
            width: node.width(),
            height: node.height(),
            rotation: node.rotation(),
            scaleX: node.scaleX(),
            scaleY: node.scaleY(),
        };
        
        // Ajuste la largeur du texte selon le scaleX
        const newWidth = Math.max(node.width() * node.scaleX(), 20);
    
        // Ajuste la largeur, réinitialise les scales et ajuste la hauteur en fonction du texte
        node.setAttrs({
            width: newWidth,
            scaleX: 1,
            scaleY: 1,
        });
    
        // Met à jour le texte et récupère la nouvelle hauteur après ajustement
        node.setAttr('text', node.text());
        const newHeight = node.height();
    
        updatedShape.width = newWidth;
        updatedShape.height = newHeight;


    
        // Met à jour les formes dans l'état avec les transformations appliquées
        const newShapes = shapes.map((shape) =>
            shape.id === updatedShape.id ? { ...shape, ...updatedShape } : shape
        );
    
        setShapes(newShapes);
    };
    
    const onTransformEndTextAI = (e: Konva.KonvaEventObject<Event>) => {
        const node = e.target as Konva.Text;
    
        const updatedShape = {
            id: node.id(),
            x: node.x(),
            y: node.y(),
            width: Math.max(node.width() * node.scaleX(), 20),
            rotation: node.rotation(),
          };
        
          // Reset scale to avoid doubling the effect
          node.scaleX(1);
          node.scaleY(1);
        
          // Update shapes in state with applied transformations
          const newShapes = shapes.map((shape) =>
            shape.id === updatedShape.id ? { ...shape, ...updatedShape } : shape
          );
        
          setShapes(newShapes);
        };


    const renderShape = (shape: any) => {
        const {
            id,
            isGradientFill,
            gradientType,
            fill,
            fillLinearGradientColorStops,
            fillLinearGradientStartPoint,
            fillLinearGradientEndPoint,
            fillRadialGradientColorStops,
            fillRadialGradientStartPoint,
            fillRadialGradientStartRadius,
            fillRadialGradientEndPoint,
            fillRadialGradientEndRadius,
            // height,
            ...restProps
        } = shape;

        const key = id;
        restProps.id = id;

        if (isGradientFill) {
            if (isGradientFill && gradientType === 'radial') {
                restProps.fillRadialGradientStartPoint = fillRadialGradientStartPoint;
                restProps.fillRadialGradientEndPoint = fillRadialGradientEndPoint;
                restProps.fillRadialGradientStartRadius = fillRadialGradientStartRadius;
                restProps.fillRadialGradientEndRadius = fillRadialGradientEndRadius;
                restProps.fillRadialGradientColorStops = fillRadialGradientColorStops;
            } else if (isGradientFill && gradientType === 'linear') {
                // Apply linear gradient
                restProps.fillLinearGradientStartPoint = fillLinearGradientStartPoint;
                restProps.fillLinearGradientEndPoint = fillLinearGradientEndPoint;
                restProps.fillLinearGradientColorStops = fillLinearGradientColorStops;
            }
        } else {
            restProps.fill = fill;
        }


        switch (shape.type) {
            case 'rect':
                return <Rect key={key} {...restProps} draggable={isDraggable} onClick={onClick} onTransformEnd={onTransformEnd} onDragEnd={onDragEnd} />;
            case 'circle':
                return <Circle key={key} {...restProps} draggable={isDraggable} onClick={onClick} onTransformEnd={onTransformEnd} onDragEnd={onDragEnd} />;
            case 'arrow':
                return <Arrow key={key} {...restProps} draggable={isDraggable} onClick={onClick} onTransformEnd={onTransformEnd} onDragEnd={onDragEnd} />;
            case 'scribble':
                return (
                    <Line
                        key={key}
                        {...restProps}
                        draggable={isDraggable}
                        lineCap="round"
                        lineJoin="round"
                        onClick={onClick}
                        onTransformEnd={onTransformEnd}
                        onDragEnd={onDragEnd}
                    />
                );
            case 'text':
                return (
                    <Text
                        key={key}
                        {...restProps}
                        draggable={isDraggable}
                        onClick={onClick}
                        onDblClick={onDoubleClick}
                        onTransform={(e) => {
                            const textNode = e.target as Konva.Text;
                            const newWidth = Math.max(textNode.width() * textNode.scaleX(), 20);
    
                            textNode.setAttrs({
                                width: newWidth,
                                scaleX: 1,
                                scaleY: 1,
                            });
                        }}
                        onTransformEnd={onTransformEndText}
                        onDragEnd={onDragEnd}
                        width={shape.width} 
                        wrap="word"
                    />
                );
            case 'textAI':
                return (
                    <Text
                        key={key}
                        {...restProps}
                        draggable={isDraggable}
                        onClick={onClick}
                        onTransform={(e) => {
                            const textNode = e.target as Konva.Text;
                            const newWidth = Math.max(textNode.width() * textNode.scaleX(), 20);
    
                            textNode.setAttrs({
                                width: newWidth,
                                scaleX: 1,
                                scaleY: 1,
                            });
                        }}
                        onTransformEnd={onTransformEndTextAI}
                        onDragEnd={onDragEnd}
                        width={shape.width} 
                        wrap="word"
                    />
                );
            case 'image':
                return <KonvaImage key={key} {...restProps} draggable={isDraggable} onClick={onClick} onTransformEnd={onTransformEnd} onDragEnd={onDragEnd} />;
            default:
                return null;
        }
    };


    const handleMouseEnter = () => {
        if (action !== ACTIONS.SELECT) {
            setCursor('crosshair');
        }
    };

    const handleMouseLeave = () => {
        setCursor('default');
    };


    return (
        <CanvasContainer
            ref={containerRef}
            cursor={cursor}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleImageUpload}
            />
            <StyledStage
                ref={stageRef}
                width={stageSize.width}
                height={stageSize.height}
                onPointerDown={onPointerDown}
                onPointerMove={onPointerMove}
                onPointerUp={onPointerUp}
                onClick={deselectShape}
            >
                <Layer>
                    {shapes.map(renderShape)}
                    <Transformer ref={transformerRef} />
                </Layer>
            </StyledStage>
        </CanvasContainer>
    );
};

export default Canvas;
