import React from 'react';
import PropertiesPanel from './PropertiesPanel';
import FlowPanel from './FlowPanel';

interface RightSidebarProps {
  selectedShape: any;
  onUpdateShape: (updates: { [key: string]: any }) => void;
  switchMode: boolean;
}

const RightSidebar: React.FC<RightSidebarProps> = ({ selectedShape, onUpdateShape, switchMode }) => {
  return (
    <div>
      {switchMode ? (
        <PropertiesPanel selectedShape={selectedShape} onChange={onUpdateShape} />
      ) : (
        <FlowPanel selectedShape={selectedShape} onChange={onUpdateShape}/>
      )}
    </div>
  );
};

export default RightSidebar;
