const createPost = {
    id: 'create-post',
    title: 'Utilisation de notre générateur de posts',
    category: 'Create Post',
    content: `
  ## Étape 1 : Connexion
  1. Connectez-vous avec votre compte LinkedIn.
  2. Autorisez les permissions nécessaires.
  
  ## Étape 2 : Créer un post
  - Rédigez votre contenu.
  - Ajoutez des images ou des vidéos.
  - Publiez en un clic !
  
  ![Interface utilisateur](https://via.placeholder.com/600x300)
    `,
  };
  
  export default createPost;
  