import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FaPlus } from "react-icons/fa6";
import { CloseOutlined } from '@ant-design/icons';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const SliderContainer = styled.div`
  padding: 20px 40px;
  width: 100%;
  overflow: hidden;
`;

const SliderCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  overflow-x: auto;
  justify-content: center; 
  align-items: center;
  gap: 5px;
  padding-top: 10px;
  padding-bottom: 10px;

   &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 60px;
  height: 80px;
  flex-shrink: 0;

  &:hover > div {
    opacity: 1;
  }
`;

const Thumbnail = styled.img<{ selected: boolean }>`
  width: 60px;
  height: 80px;
  border: ${(props) => (props.selected ? '2px solid #1890ff' : '1px solid #ccc')};
  cursor: pointer;
  border-radius: 5px;
`;

const AddButton = styled.div`
  width: 60px;
  height: 80px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  color: #ccc;
  flex-shrink: 0;
`;

const RemoveButton = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background: #e35252;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
`;

interface SlideNavigatorProps {
  slides: any[][];
  selectedSlideIndex: number;
  onAddNewSlide: () => void;
  onSelectSlide: (index: number) => void;
  onReorderSlides: (newSlides: any[][]) => void;
  stageRef: React.RefObject<any>;
  transformerRef: React.RefObject<any>;
  setSelectedShape: React.Dispatch<React.SetStateAction<any>>;
  setSelectedKonvaShape: React.Dispatch<React.SetStateAction<any>>;
  onRemoveSlide: (index: number) => void;
}

const SlideNavigator: React.FC<SlideNavigatorProps> = ({
  slides,
  selectedSlideIndex,
  onAddNewSlide,
  onSelectSlide,
  onReorderSlides,
  stageRef,
  transformerRef,
  setSelectedShape,
  setSelectedKonvaShape,
  onRemoveSlide,
}) => {
  const [thumbnails, setThumbnails] = React.useState<string[]>([]);

  useEffect(() => {
    const generateThumbnailForCurrentSlide = () => {
      if (stageRef.current) {
        const newThumbnail = stageRef.current.toDataURL({ pixelRatio: 0.1 });
        setThumbnails((prevThumbnails) => {
          const updatedThumbnails = [...prevThumbnails];
          updatedThumbnails[selectedSlideIndex] = newThumbnail;
          return updatedThumbnails;
        });
      }
    };

    generateThumbnailForCurrentSlide();
  }, [slides[selectedSlideIndex], selectedSlideIndex, stageRef]);

  const handleSlideSelect = (index: number) => {
    onSelectSlide(index);

    if (transformerRef.current) {
      transformerRef.current.nodes([]);
      transformerRef.current.getLayer().batchDraw();
    }
    setSelectedShape(null);
    setSelectedKonvaShape(null);
  };

  const moveSlide = (dragIndex: number, hoverIndex: number) => {
    const reorderedSlides = [...slides];
    const [dragged] = reorderedSlides.splice(dragIndex, 1);
    reorderedSlides.splice(hoverIndex, 0, dragged);
    onReorderSlides(reorderedSlides);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <SliderContainer>
        <SliderCards>
          {slides.map((_, index) => (
            <Slide
              key={index}
              index={index}
              selected={index === selectedSlideIndex}
              thumbnail={thumbnails[index] || ''}
              onClick={() => handleSlideSelect(index)}
              onRemove={() => onRemoveSlide(index)}
              moveSlide={moveSlide}
            />
          ))}
          <AddButton onClick={onAddNewSlide}>
            <FaPlus />
          </AddButton>
        </SliderCards>
      </SliderContainer>
    </DndProvider>
  );
};

interface SlideProps {
  index: number;
  selected: boolean;
  thumbnail: string;
  onClick: () => void;
  onRemove: () => void;
  moveSlide: (dragIndex: number, hoverIndex: number) => void;
}

const Slide: React.FC<SlideProps> = ({ index, selected, thumbnail, onClick, onRemove, moveSlide }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'slide',
    hover: (item: { index: number }) => {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveSlide(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'slide',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <ThumbnailWrapper ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Thumbnail src={thumbnail} selected={selected} onClick={onClick} />
      <RemoveButton onClick={onRemove}>
        <CloseOutlined />
      </RemoveButton>
    </ThumbnailWrapper>
  );
};

export default SlideNavigator;
