// src/hooks/useTemplate.ts
import React, { useContext, useEffect, useState } from 'react';
import { PostTemplateModelApi } from '../component/TemplateEditor/models/PostTemplateModelApi';
import { AuthContext } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom';

const exempleTemplate = [
  [
      {
          "id": "b0954016-4304-4a4a-8a83-a4561b15bedd",
          "name": "Background",
          "type": "image",
          "x": 0,
          "y": -2,
          "image": {},
          "imageFile": {},
          "imageSrc": "http://localhost:3001/api/post-templates/a3882005-7d37-47c5-9cf5-2d834b95df0a/images/3b6dc261-2109-48d2-973b-e797c5a281db",
          "isSavedToBackend": true,
          "width": 160,
          "height": 200,
          "rotation": 0,
          "scaleX": 1.702513385929254,
          "scaleY": 1.7025133859292536
      },
      {
          "id": "359818c3-4bf9-4f31-b595-ff033ec7a9fb",
          "name": "Profile",
          "type": "image",
          "x": 12.999999999999977,
          "y": 40.73741379813593,
          "image": {},
          "imageFile": {},
          "imageSrc": "http://localhost:3001/api/post-templates/a3882005-7d37-47c5-9cf5-2d834b95df0a/images/d3d65a72-72b1-41ed-8081-6d18c4de329a",
          "isSavedToBackend": true,
          "width": 200,
          "height": 200,
          "rotation": 0,
          "scaleX": 0.25131293100932184,
          "scaleY": 0.2513129310093221
      },
      {
          "id": "d8d9b879-f388-496f-a948-2beb7d460142",
          "type": "textAI",
          "x": 13.5,
          "y": 103.5,
          "text": "Lorem ipsum dolor sit amet, consect",
          "fontSize": 24,
          "fontFamily": "Arial",
          "fill": "rgba(255, 255, 255, 1)",
          "contentType": "title",
          "prePrompt": "The subject title",
          "maxCharacters": 35,
          "lineHeight": 1,
          "name": "Title",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 224.00000000000003,
          "isGradientFill": false,
          "fontStyle": "bold"
      },
      {
          "id": "6aff8903-9b3e-4467-841c-a14564dd0a5e",
          "type": "textAI",
          "x": 14.5,
          "y": 159.5,
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lor",
          "fontSize": 14,
          "fontFamily": "Arial",
          "fill": "rgba(255, 255, 255, 1)",
          "contentType": "subtitle",
          "prePrompt": "The subject subtitle",
          "maxCharacters": 60,
          "lineHeight": 1,
          "name": "Sub-Title",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 200,
          "isGradientFill": false
      }
  ],
  [
      {
          "id": "565138ae-6883-486d-a37b-c05f70b59455",
          "name": "Background",
          "type": "image",
          "x": -1,
          "y": -1,
          "image": {},
          "imageFile": {},
          "imageSrc": "http://localhost:3001/api/post-templates/a3882005-7d37-47c5-9cf5-2d834b95df0a/images/660baa23-c553-4b6a-923f-4b5a1182fa6e",
          "isSavedToBackend": true,
          "width": 160,
          "height": 200,
          "rotation": 0,
          "scaleX": 1.7000403510275723,
          "scaleY": 1.7000403510275723
      },
      {
          "id": "fcb69440-b554-4802-977d-ee99d7c2735d",
          "type": "textAI",
          "x": 67.5,
          "y": 66.50000000000004,
          "text": "Lorem ipsum dolor sit amet, consect",
          "fontSize": 20,
          "fontFamily": "Arial",
          "fill": "#000000",
          "contentType": "title",
          "prePrompt": "\nTitle of the first point",
          "maxCharacters": 35,
          "lineHeight": 1,
          "name": "Title",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 183.00000000000003,
          "fontStyle": "bold"
      },
      {
          "id": "908d1468-9973-46ba-996d-8f12c666c053",
          "type": "textAI",
          "x": 41.5,
          "y": 143.5,
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, c",
          "fontSize": 14,
          "fontFamily": "Arial",
          "fill": "#000000",
          "contentType": "content",
          "prePrompt": "Content of the first point",
          "maxCharacters": 200,
          "lineHeight": 1,
          "name": "Content",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 200
      }
  ],
  [
      {
          "id": "26d4e4e1-9b47-4431-8dfc-e8151af319b2",
          "name": "Background",
          "type": "image",
          "x": 0,
          "y": 0,
          "image": {},
          "imageFile": {},
          "imageSrc": "http://localhost:3001/api/post-templates/a3882005-7d37-47c5-9cf5-2d834b95df0a/images/c67213d4-f145-4d12-8bf7-dbdf1d80b0cf",
          "isSavedToBackend": true,
          "width": 270,
          "height": 338
      },
      {
          "id": "eeff765d-a24a-4246-9030-805fe03b250b",
          "type": "textAI",
          "x": 85.5,
          "y": 68.49999999999993,
          "text": "Lorem ipsum dolor sit amet, co",
          "fontSize": 20,
          "fontFamily": "Arial",
          "fill": "#000000",
          "contentType": "title",
          "prePrompt": "Title of the second point",
          "maxCharacters": 30,
          "lineHeight": 1,
          "name": "Title",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 168.9999999999999,
          "fontStyle": "bold"
      },
      {
          "id": "58885df6-9e32-4415-bbdc-e46469725c9a",
          "type": "textAI",
          "x": 44.5,
          "y": 144.5,
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, c",
          "fontSize": 14,
          "fontFamily": "Arial",
          "fill": "#000000",
          "contentType": "content",
          "prePrompt": "content of the second point",
          "maxCharacters": 200,
          "lineHeight": 1,
          "name": "Content",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 200
      }
  ],
  [
      {
          "id": "88aa8a2d-6866-43f9-9231-d486f02d0452",
          "name": "Background",
          "type": "image",
          "x": -1,
          "y": -1,
          "image": {},
          "imageFile": {},
          "imageSrc": "http://localhost:3001/api/post-templates/a3882005-7d37-47c5-9cf5-2d834b95df0a/images/f82cf1ab-903a-4c84-a2ac-77dfa0e76d22",
          "isSavedToBackend": true,
          "width": 160,
          "height": 200,
          "rotation": 0,
          "scaleX": 1.7202390999438444,
          "scaleY": 1.7202390999438448
      },
      {
          "id": "40fbeb38-4901-4db3-a58e-32bd72495420",
          "type": "textAI",
          "x": 90.49999999999999,
          "y": 66.5,
          "text": "Lorem ipsum dolor sit amet, co",
          "fontSize": 20,
          "fontFamily": "Arial",
          "fill": "#000000",
          "contentType": "title",
          "prePrompt": "Title of the third point",
          "maxCharacters": 30,
          "lineHeight": 1,
          "name": "Title",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 169.99999999999983,
          "fontStyle": "bold"
      },
      {
          "id": "7270a3f4-6fbe-4c64-8211-f16a6b1b92a3",
          "type": "textAI",
          "x": 43.5,
          "y": 145.5,
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, c",
          "fontSize": 14,
          "fontFamily": "Arial",
          "fill": "#000000",
          "contentType": "content",
          "prePrompt": "content of the third point",
          "maxCharacters": 200,
          "lineHeight": 1,
          "name": "Content",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 200
      }
  ],
  [
      {
          "id": "1a177103-d36d-446c-ad75-7df262ec4c63",
          "name": "Background",
          "type": "image",
          "x": -1,
          "y": -1,
          "image": {},
          "imageFile": {},
          "imageSrc": "http://localhost:3001/api/post-templates/a3882005-7d37-47c5-9cf5-2d834b95df0a/images/a8b00f19-1c15-4a90-9d64-0cc1ea43782c",
          "isSavedToBackend": true,
          "width": 270,
          "height": 338
      },
      {
          "id": "28903545-942c-4ff6-96ed-ec2817f613c2",
          "type": "textAI",
          "x": 92.5,
          "y": 67.49999999999991,
          "text": "Lorem ipsum dolor sit amet, co",
          "fontSize": 20,
          "fontFamily": "Arial",
          "fill": "#000000",
          "contentType": "title",
          "prePrompt": "Title of the fourth point",
          "maxCharacters": 30,
          "lineHeight": 1,
          "name": "Title",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 168.9999999999998,
          "fontStyle": "bold"
      },
      {
          "id": "fbd65fd6-2ab5-41a8-95c1-1be86e22547c",
          "type": "textAI",
          "x": 47.5,
          "y": 150.5,
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, c",
          "fontSize": 14,
          "fontFamily": "Arial",
          "fill": "#000000",
          "contentType": "content",
          "prePrompt": "Content of the fourth point",
          "maxCharacters": 200,
          "lineHeight": 1,
          "name": "Content",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 200
      }
  ],
  [
      {
          "id": "a8b8b6e3-7e77-42fd-bf6f-ada7c9d5e1c1",
          "name": "Image 3",
          "type": "image",
          "x": 0,
          "y": 0,
          "image": {},
          "imageFile": {},
          "imageSrc": "http://localhost:3001/api/post-templates/a3882005-7d37-47c5-9cf5-2d834b95df0a/images/c40285be-a2c8-4c6d-b0b1-3dd7b8601a33",
          "isSavedToBackend": true,
          "width": 270,
          "height": 338
      },
      {
          "id": "33b6d62c-a82a-4f98-aa9b-a5e52cde8496",
          "type": "textAI",
          "x": 42.5,
          "y": 152.5,
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, c",
          "fontSize": 14,
          "fontFamily": "Arial",
          "fill": "#000000",
          "contentType": "content",
          "prePrompt": "Content of the fifth point",
          "maxCharacters": 200,
          "lineHeight": 1,
          "name": "Content",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 200
      },
      {
          "id": "ec6b152b-846e-4b9e-9f32-f634951dfca8",
          "type": "textAI",
          "x": 77.5,
          "y": 79.50000000000001,
          "text": "Lorem ipsum dolor sit amet, co",
          "fontSize": 20,
          "fontFamily": "Arial",
          "fill": "#000000",
          "contentType": "title",
          "prePrompt": "Title of the fifth point",
          "maxCharacters": 30,
          "lineHeight": 1,
          "name": "Title",
          "rotation": 0,
          "opacity": 1,
          "visible": true,
          "width": 183.99999999999972,
          "fontStyle": "bold"
      }
  ],
  [
      {
          "id": "06b7f166-04a1-4e5e-98e2-32650f82ce97",
          "name": "Background",
          "type": "image",
          "x": -1,
          "y": -1,
          "image": {},
          "imageFile": {},
          "imageSrc": "http://localhost:3001/api/post-templates/a3882005-7d37-47c5-9cf5-2d834b95df0a/images/bd635732-db32-417c-a962-35ffbd71b8a2",
          "isSavedToBackend": true,
          "width": 270,
          "height": 338
      },
      {
          "id": "f3e43db2-2214-495d-9f29-e4a5225bb7fe",
          "type": "rect",
          "x": 34.592514938969,
          "y": 108.66462822526378,
          "width": 180,
          "height": 62,
          "fill": "rgba(255, 255, 255, 1)",
          "isGradientFill": false,
          "name": "Rectangle 2",
          "rotation": -5.960400543378139,
          "stroke": "#000000",
          "strokeWidth": 0,
          "shadowBlur": 0,
          "cornerRadius": 8,
          "opacity": 1,
          "visible": true,
          "scaleX": 1.1189204297461608,
          "scaleY": 1.0000000000000064
      },
      {
          "id": "fd62034c-092f-4a3f-945c-9e662d35e084",
          "name": "Profile",
          "type": "image",
          "x": 44,
          "y": 248.0845214189904,
          "image": {},
          "imageFile": {},
          "imageSrc": "http://localhost:3001/api/post-templates/a3882005-7d37-47c5-9cf5-2d834b95df0a/images/87b7a75e-124a-46ea-aee5-67c54c3e4acd",
          "isSavedToBackend": true,
          "width": 200,
          "height": 200,
          "rotation": 0,
          "scaleX": 0.18957739290504852,
          "scaleY": 0.18957739290504852
      },
      {
          "id": "c71d98a3-b2c4-4807-9272-cffe1e6d655a",
          "type": "text",
          "x": 39.5,
          "y": 37.5,
          "text": "Learned Something?",
          "fontSize": 24,
          "fontFamily": "Calibri",
          "fill": "rgba(255, 255, 255, 1)",
          "isGradientFill": false,
          "name": "Learned",
          "rotation": 0,
          "stroke": "#000000",
          "strokeWidth": 0,
          "shadowBlur": 0,
          "opacity": 1,
          "visible": true,
          "width": 212,
          "wrap": "word",
          "lineHeight": 1,
          "height": 24,
          "scaleX": 1,
          "scaleY": 1
      },
      {
          "id": "552f0ece-b423-4a04-bcb2-efc63a8a6035",
          "type": "text",
          "x": 91.5,
          "y": 252.5,
          "text": "Jean Bonneau",
          "fontSize": 14,
          "fontFamily": "Calibri",
          "fill": "rgba(255, 255, 255, 1)",
          "isGradientFill": false,
          "name": "Name",
          "rotation": 0,
          "stroke": "#000000",
          "strokeWidth": 0,
          "shadowBlur": 0,
          "opacity": 1,
          "visible": true,
          "width": 97,
          "wrap": "word",
          "lineHeight": 1,
          "height": 14,
          "scaleX": 1,
          "scaleY": 1
      },
      {
          "id": "2b296793-1353-46cc-9210-ab195f6c2300",
          "type": "text",
          "x": 89.5,
          "y": 269.5000000000001,
          "text": "@jean_bonneau",
          "fontSize": 12,
          "fontFamily": "Calibri",
          "fill": "rgba(247, 247, 247, 1)",
          "isGradientFill": false,
          "name": "lkn name",
          "rotation": 0,
          "stroke": "#000000",
          "strokeWidth": 0,
          "shadowBlur": 0,
          "opacity": 1,
          "visible": true,
          "width": 102.00000000000003,
          "wrap": "word",
          "lineHeight": 1,
          "fontStyle": "italic",
          "height": 14,
          "scaleX": 1,
          "scaleY": 1
      },
      {
          "id": "c12996d3-38ef-4876-ac95-3897cdcc5b5a",
          "type": "text",
          "x": 73.67454829536094,
          "y": 125.16461000384113,
          "text": "Follow me !",
          "fontSize": 24,
          "fontFamily": "Calibri",
          "fill": "#000000",
          "isGradientFill": false,
          "name": "follow",
          "rotation": -5.15538374929394,
          "stroke": "#000000",
          "strokeWidth": 0,
          "shadowBlur": 0,
          "opacity": 1,
          "visible": true,
          "width": 124.99999999999982,
          "wrap": "word",
          "lineHeight": 1,
          "height": 24,
          "scaleX": 1,
          "scaleY": 1
      }
  ]
];

const useTemplate = (
  setSlides: React.Dispatch<React.SetStateAction<any[][]>>,
  setSelectedSlideIndex: React.Dispatch<React.SetStateAction<number>>,
  setSelectedShape: React.Dispatch<React.SetStateAction<any>>,
  setSelectedKonvaShape: React.Dispatch<React.SetStateAction<any>>,
  executePendingDeletes: () => void,
) => {
  const [templates, setTemplates] = useState<PostTemplateModelApi[]>([]);
  const [currentTemplate, setCurrentTemplate] = useState<PostTemplateModelApi | null>(null);
  const { myToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  const deserializeSlides = (slidesData: any) => {
    if (!Array.isArray(slidesData)) {
      return [[]];
    }
    return slidesData.map((slide: any) =>
      Array.isArray(slide)
        ? slide.map((shape: any) => {
          if (shape.type === 'image' && shape.imageSrc) {
            const img = new Image();
            img.src = shape.imageSrc;
            return {
              ...shape,
              image: img,
            };
          }
          return shape;
        })
        : [],
    );
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/post-templates/user`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    })
      .then((response) => response.json())
      .then((data: PostTemplateModelApi[]) => {
        if (data && data.length > 0) {
          setTemplates(data);
          const firstTemplateId = data[0].id;
          fetch(`${process.env.REACT_APP_API_URL}/post-templates/${firstTemplateId}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${myToken}`,
            },
          })
            .then((response) => response.json())
            .then((templateData: PostTemplateModelApi) => {
              setCurrentTemplate(templateData);
              const jsonData = templateData.jsonData ? JSON.parse(templateData.jsonData) : [[]];
              const parsedSlides = deserializeSlides(jsonData);

              setSlides(parsedSlides);
            })
            .catch((error) => console.error('Error fetching template content:', error));
        } else {
          setCurrentTemplate({ name: 'New Template', jsonData: '', id: undefined });
          setSlides([[]]);
        }
      })
      .catch((error) => console.error('Error fetching templates:', error));
  }, [myToken, setSlides]);

  useEffect(() => {
    if (currentTemplate && currentTemplate.id === undefined && loading) {
      setLoading(false);
      console.log('currentTemplate', currentTemplate);
      handleSave([[]]);
    }
  }, [currentTemplate, loading]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    if (currentTemplate) {
      setCurrentTemplate({ ...currentTemplate, name: newName });
    }
  };

  const handleMenuClick = (e: any) => {
    if (e.key === 'new') {
      handleNewTemplate();
    }
    else {
      const selectedTemplateId = e.key;
      fetch(`${process.env.REACT_APP_API_URL}/post-templates/${selectedTemplateId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${myToken}`,
        },
      })
        .then((response) => response.json())
        .then((templateData: PostTemplateModelApi) => {
          setCurrentTemplate(templateData);
          const jsonData = JSON.parse(templateData.jsonData);
          const parsedSlides = deserializeSlides(jsonData);

          setSlides(parsedSlides);
          setSelectedSlideIndex(0);
          setSelectedShape(null);
          setSelectedKonvaShape(null);
        })
        .catch((error) => console.error('Error fetching template content:', error));
    }
  };

  const serializeSlides = (slides: any[][]) => {
    return slides.map((slide) =>
      slide.map((shape) => {
        if (shape.type === 'image' && shape.image) {
          return {
            ...shape,
            imageSrc: shape.image.src,
            image: undefined,
          };
        }
        return shape;
      }),
    );
  };

  const uploadUnsavedImages = async (
    slides: any[][],
    currentTemplate: PostTemplateModelApi | null,
  ): Promise<any[][]> => {
    return await Promise.all(
      slides.map(async (slide) =>
        await Promise.all(
          slide.map(async (shape) => {
            if (shape.type === 'image' && !shape.isSavedToBackend) {
              const formData = new FormData();
              formData.append('file', shape.imageFile); // Utilise `imageFile` pour l'upload

              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/post-templates/${currentTemplate?.id}/images`,
                {
                  method: 'POST',
                  headers: {
                    Authorization: `Bearer ${myToken}`,
                  },
                  body: formData,
                }
              );

              if (response.ok) {
                const result = await response.json();
                shape.imageSrc = `${process.env.REACT_APP_API_URL}/post-templates/${currentTemplate?.id}/images/${result.id}`;
                shape.isSavedToBackend = true;
              } else {
                throw new Error('Erreur lors de la sauvegarde de l’image');
              }
            }
            return shape;
          })
        )
      )
    );
  };

  const handleSave = async (slides: any[][]): Promise<{ status: boolean; message: string }> => {
    console.log('try to save');
    try {
      // const serializedSlides = serializeSlides(slides);
      await executePendingDeletes();
      const serializedSlides = await uploadUnsavedImages(slides, currentTemplate);
      const payload = {
        name: currentTemplate?.name || 'Unnamed Template',
        jsonData: JSON.stringify(serializedSlides),
      };

      if (currentTemplate?.id) {
        // Update existing template
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/post-templates/${currentTemplate.id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${myToken}`,
            },
            body: JSON.stringify(payload),
          },
        );

        if (!response.ok) {
          return { status: false, message: 'Error updating template' };
        }

        const data: PostTemplateModelApi = await response.json();
        setCurrentTemplate(data);
        setTemplates((prevTemplates) =>
          prevTemplates.map((template) =>
            template.id === data.id ? data : template,
          ),
        );
        return { status: true, message: 'Template updated successfully' };
      } else {
        // Create new template
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/post-templates`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${myToken}`,
            },
            body: JSON.stringify(payload),
          },
        );

        if (!response.ok) {
          return { status: false, message: 'Error creating template' };
        }

        const data: PostTemplateModelApi = await response.json();
        setCurrentTemplate(data);
        setTemplates((prevTemplates) => [...prevTemplates, data]);
        return { status: true, message: 'Template created successfully' };
      }
    } catch (error) {
      console.error('Error saving template:', error);
      return { status: false, message: 'Error saving template' };
    }
  };


  const handleDelete = async () => {
    if (!currentTemplate?.id) {
      console.error('Aucun template sélectionné pour suppression');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/post-templates/${currentTemplate.id}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${myToken}`,
            'Accept': '*/*',
          },
        },
      );

      if (!response.ok) {
        throw new Error(`Erreur lors de la suppression du template: ${response.statusText}`);
      }


      // Supprimer le template de la liste locale après suppression
      setTemplates((prevTemplates) =>
        prevTemplates.filter((template) => template.id !== currentTemplate.id),
      );

      // Réinitialiser le template courant si nécessaire
      setCurrentTemplate(null);
      navigate(0);
    } catch (error) {
      console.error('Erreur lors de la suppression du template:', error);
    }
  };

  const handleNewTemplate = () => {
    setCurrentTemplate({ name: 'Nouvelle Template', jsonData: '', id: undefined });
    setSlides([[]]);
    setSelectedSlideIndex(0);
    setSelectedShape(null);
    setSelectedKonvaShape(null);
  };

  return {
    // État et références
    templates,
    currentTemplate,

    // Handlers
    handleNameChange,
    handleMenuClick,
    handleSave,
    handleDelete,
    handleNewTemplate,
  };
};

export default useTemplate;
