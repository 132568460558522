// src/auth/CguRoute.jsx
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

export const CguRoute = ({ children }) => { // Remplacez `component` par `children`
  const { isLoading, cguAccepted } = useContext(AuthContext);

  if (isLoading) {
    return <div>Chargement...</div>; // Afficher l'état de chargement
  }

  return cguAccepted ? children : <Navigate to="/cgu" />;
};
