import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Channel, Video } from '../../types';
import { AuthContext } from '../../auth/AuthContext';
import { Input, Modal, Spin } from 'antd';
import { ListVideo } from './List';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { YoutubeVideoApiResponse } from '../../model/YoutubeModelApi';
import { Alert } from 'antd';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 20px;
`;

const ChannelContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

const ChannelButtonAdd = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #636D79;
    color: #636D79;
    background-color: transparent;
    cursor: pointer;
    height: 50px;
    width: 50px;
    transition: background 0.3s;

    font-size: 16px;
    font-weight: bold;

    &:hover {
        background: #ffffff;
    }
`;

const ScrollableListContainer = styled.div`
    height: 100%;
    background: #fff;
    border-radius: 25px;
    padding: 20px 30px;
`;

const SpinContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`;

const ChannelImageContainer = styled.div`
    position: relative;
    border-radius: 50%;
    width: 50px;
    height: 50px;
`;

const ChannelImage = styled.img<{ isSelected: boolean }>`
  border-radius: 50%;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: 3px solid ${props => props.isSelected ? '#89B9A1' : 'transparent'};
  transition: box-shadow 0.2s ease-out, border-color 0.2s ease-in;

  &:hover {
    box-shadow: ${props => props.isSelected ? '' : '0 0 20px rgba(0,0,0,0.4)'};
  }
`;

const ChannelFallbackStyled = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer; // Curseur pointer
  border: 3px solid ${props => (props.isSelected ? '#89B9A1' : 'transparent')};
  transition: box-shadow 0.2s ease-out, border-color 0.2s ease-in;

  &:hover {
    box-shadow: ${props => (props.isSelected ? '' : '0 0 20px rgba(0,0,0,0.4)')};
  }
`;

const DeleteChannel = styled.div`
    position: absolute;
    top: -5px; // Adjust based on visual needs
    right: -5px; // Adjust based on visual needs
    height: 20px;
    width: 20px;
    background: #FE5747;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; // For potential icons or text
    font-size: 10px;

    transition: background 0.3s;

    &:hover {
        background: #D63D2A;
    }
`;

const ContainerEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const ContainerEmptyTexte = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


const getBackgroundColorFromLetter = (letter: string) => {
  const colors = [
    '#F28B82', '#FBBC04', '#FFF475', '#CCFF90', '#A7FFEB',
    '#CBF0F8', '#AECBFA', '#D7AEFB', '#FDCFE8', '#E6C9A8'
  ];
  const index = letter.toUpperCase().charCodeAt(0) % colors.length;
  return colors[index];
};

const ChannelFallback: React.FC<{ name: string; size?: number; style?: React.CSSProperties; isSelected: boolean }> = ({
  name,
  size = 50,
  style,
  isSelected
}) => {
  const letter = name.charAt(0).toUpperCase();
  const backgroundColor = getBackgroundColorFromLetter(letter);

  return (
    <ChannelFallbackStyled
      style={{
        width: size,
        height: size,
        backgroundColor,
        ...style, // Combine les styles par défaut et personnalisés
      }}
      isSelected={isSelected} // Passe l'état sélectionné
    >
      {letter}
    </ChannelFallbackStyled>
  );
};
type YoutubeProps = {
  onAction: (video: Video) => void;
};


export const YoutubeComponent: React.FC<YoutubeProps> = ({ onAction }) => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { myToken } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchError, setSearchError] = useState('');
  const [channelSelected, setChannelSelected] = useState<Channel | null>(null);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  // const [containerHeight, setContainerHeight] = useState(0);

  const handleDeleteChannel = (channelId: number) => {
    setLoading(true);
    axios.delete(`${process.env.REACT_APP_API_URL}/youtube/channels/${channelId}`, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(() => {
      const currentChannelIndex = channels.findIndex((channel: Channel) => channel.id === channelId);
      const newChannels = channels.filter((channel: Channel) => channel.id !== channelId);
      setChannels(newChannels);

      // Update the selected channel
      if (newChannels.length === 0) {
        setChannelSelected(null); // No channels left
        setVideos([]);
      } else if (currentChannelIndex === 0) {
        setChannelSelected(newChannels[0]); // Select the first channel if the deleted one was the first
      } else {
        setChannelSelected(newChannels[Math.max(0, currentChannelIndex - 1)]); // Select the previous channel or the first if out of range
      }
      setLoading(false);
    }).catch(error => {
      console.error('Error deleting channel:', error);
      setLoading(false);
    });
  };

  useEffect(() => {
    const updateHeight = () => {
      if (listContainerRef.current) {
        const measurements = listContainerRef.current.getBoundingClientRect();
        // setContainerHeight(measurements.height - 40);
      }
    };

    updateHeight(); // Update height initially
    window.addEventListener('resize', updateHeight); // Update height on resize

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [listContainerRef]);

  useEffect(() => {
    if (!channelSelected) {
      return;
    }
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/youtube/videos/${channelSelected.id}`, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(response => {
      const fetchedVideos = response.data.map((video: YoutubeVideoApiResponse) => ({
        videoId: video.videoId,
        title: video.title,
        publishedDate: video.releaseDate,
        description: video.description,
        channelTitle: video.channelName,
        thumbnailUrl: video.imageUrl,
      }));
      setVideos(fetchedVideos);
      setLoading(false);
    }).catch(error => {
      console.error('Error fetching videos:', error);
      setLoading(false);
    });
  }, [channelSelected, myToken]);

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/youtube/channels`, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(response => {
      const fetchedChannels = response.data.map((channel: any) => ({
        id: channel.id,
        youtubeChannelId: channel.youtubeChannelId,
        youtubeChanelName: channel.youtubeChanelName,
        youtubeChanelImage: channel.youtubeChanelImage,
      }));
      if (fetchedChannels.length > 0) {
        setChannelSelected(fetchedChannels[0]);
      }
      setChannels(fetchedChannels);
      setLoading(false);
    }).catch(error => {
      console.error('Error fetching channels:', error);
      setLoading(false);
    });
  }, [myToken]);


  const handleSearch = () => {
    if (!searchTerm) {
      setSearchError('Please enter a search term');
      return;
    }
    setSearchError('');
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/youtube/channels`, { url: searchTerm }, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(response => {
      setChannels([...channels, response.data]);
      setIsModalOpen(false); // Close the modal after adding
      setLoading(false);
      setChannelSelected(response.data);
    }).catch(error => {
      console.error('Error adding channel:', error);
      setSearchError('Failed to add channel');
      setLoading(false);
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    handleSearch();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSearchTerm(''); // Clear the search term on cancel
    setSearchError(''); // Clear any errors
  };
  return (
    <Container>
      <Modal title="Add Channel" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Input placeholder="Enter channel URL (ex: https://www.youtube.com/@PostPilot)" value={searchTerm} onChange={e => setSearchTerm(e.target.value)}
          maxLength={1000} />
        {searchError && <p style={{ color: 'red' }}>{searchError}</p>}
      </Modal>
      {channels.length === 0 ?
        <ContainerEmpty>
          <ContainerEmptyTexte>
            <p>Use content from your favorite videos to write a post! ✍️ </p>
            <p>Add your top channels to never miss a thing! ⭐</p>
          </ContainerEmptyTexte>
          <ChannelButtonAdd style={{ height: 70, width: 70 }} onClick={showModal}>
            <PlusOutlined style={{ fontSize: "28px" }} />
          </ChannelButtonAdd>

        </ContainerEmpty>
        :
        <>
          <ChannelContainer>
            <ChannelButtonAdd onClick={showModal}>
              <PlusOutlined />
            </ChannelButtonAdd>
            {channels.map(channel => (
              <ChannelImageContainer
                key={channel.youtubeChannelId}
                onClick={() => setChannelSelected(channel)} // Définit le canal sélectionné
              >
                {channel.youtubeChanelImage ? (
                  <ChannelImage
                    src={channel.youtubeChanelImage}
                    alt={channel.youtubeChanelName}
                    isSelected={channel === channelSelected} // Visualisation : bordure
                    onError={e => {
                      e.currentTarget.style.display = 'none'; // Masquer l'image si erreur
                      const fallback = e.currentTarget.nextSibling as HTMLElement;
                      if (fallback) fallback.style.display = 'flex'; // Afficher le fallback
                    }}
                  />
                ) : (
                  <ChannelFallback
                    name={channel.youtubeChanelName}
                    isSelected={channel === channelSelected} // Bordure verte si sélectionné
                  />
                )}
                <ChannelFallback
                  name={channel.youtubeChanelName}
                  style={{ display: 'none' }}
                  isSelected={channel === channelSelected}
                />
                {channel === channelSelected && (
                  <DeleteChannel onClick={() => handleDeleteChannel(channel.id)}>
                    <CloseOutlined />
                  </DeleteChannel>
                )}
              </ChannelImageContainer>
            ))}
          </ChannelContainer>
          <ScrollableListContainer ref={listContainerRef}>
            {loading && <SpinContainer>
              <Spin />
            </SpinContainer>}
            <ListVideo videos={videos} containerHeight={400} onAction={onAction} />
          </ScrollableListContainer>
        </>
      }
    </Container>
  );
};
