import React, { useEffect, useState } from 'react';
import { Button, Input, message, Modal, Pagination, Spin } from 'antd';
import { ImageBankApi } from '../../model/ImageBankApi';
import { ImageBankService } from '../../service/ImageBankService';
import styled from 'styled-components';


interface ImageBankModalProps {
  token: string
  visible: boolean,
  setVisible: (value: boolean) => void
  acceptImage: (url: string) => void
}

const ImageBankModal: React.FC<ImageBankModalProps> = ({ acceptImage, token, visible, setVisible }) => {
  
  const [loadingImageBank, setLoadingImageBank] = useState(false);
  const [imagesBank, setImagesBank] = useState<ImageBankApi[]>([]);
  const [imagesBankPage, setImagesBankPage] = useState<ImageBankApi[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  
  const handleImageBankModalCancel = () => {
    setVisible(false);
  };
  
  useEffect(() => {
    onPaginationChange(1, 6);
  }, [imagesBank]);
  
  const fetchImageBank = async () => {
    setLoadingImageBank(true);
    try {
      const images = ImageBankService.getImageBankByKeyword(token, searchQuery).then(images => {
        if (!images) {
          throw new Error();
        }
        setImagesBank(images);
      });
    } catch (error) {
      message.error('Failed to fetch image bank');
    } finally {
      setLoadingImageBank(false);
    }
  };
  
  const selectImage = (imageUrl: string) => {
    if (imageUrl === selectedImage) {
      setSelectedImage(null);
    } else {
      setSelectedImage(imageUrl!);
    }
  };
  
  const onPaginationChange = (pageNumber: number, pageSize: number) => {
    const images = imagesBank.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    setImagesBankPage(images);
  };
  
  return (
    <Modal
      title="Choose an image"
      visible={visible}
      onCancel={handleImageBankModalCancel}
      width={'60%'}
      footer={[
        <Button key="cancel" onClick={() => setVisible(false)}>
          Annuler
        </Button>,
        <Button key="confirm" type="primary" onClick={() => acceptImage(selectedImage!)} disabled={!selectedImage}>
          Confirmer
        </Button>,
      ]}
    >
      {loadingImageBank ? (
        <Spin />
      ) : (
        <>
          <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
            <Input
              placeholder="Type a keyword"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onPressEnter={fetchImageBank}
            />
            <Button type="primary" onClick={fetchImageBank}>
              Search
            </Button>
          </div>
          <ImageBankPaginationContainer>
            <ImageBankPhotoContainer onClick={(e) => e.stopPropagation()}>
              {imagesBankPage.length > 0 && imagesBankPage.map((image, index) => (
                <ImageBankPhotoItem selected={selectedImage === image.srcLarge} key={index} src={image.srcLarge}
                                    alt={image.alt || `Photo ${index}`}
                                    onClick={e => selectImage(image.srcLarge!)} />
              ))}
            </ImageBankPhotoContainer>
            {imagesBankPage.length > 0 &&
              <Pagination defaultCurrent={1} total={imagesBank.length} pageSize={6} onChange={onPaginationChange} />}
          </ImageBankPaginationContainer>
        </>
      )
      }
    </Modal>
  )
    ;
};

const ImageBankPhotoContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    width: 100%;
    align-content: center;
    justify-content: center;
`;

const ImageBankPaginationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

const ImageBankPhotoItem = styled.img<{ selected: boolean }>`
    height: 150px;
    width: auto;
    border-radius: 4px;
    cursor: pointer;
    border: ${({ selected }) => (selected ? '6px solid #028CB5' : '3px solid transparent')};
    transition: border 0.3s;

    &:hover {
        border: ${({ selected }) => (selected ? '6px solid #028CB5' : '3px solid #028CB5')};
    }
`;

export default ImageBankModal;
