import React, { useState } from 'react';
import { Modal, DatePicker, Radio, message } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
`;

interface ConfirmationModalProps {
    visible: boolean;
    onConfirm: (publishDate?: string) => void; // Ajout d'une date optionnelle
    onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ visible, onConfirm, onCancel }) => {
    const [publishDate, setPublishDate] = useState<string | null>(null);
    const [postOption, setPostOption] = useState<'now' | 'schedule'>('now');

    // Gère la sélection de la date
    const handleDateChange = (date: dayjs.Dayjs | null) => {
        setPublishDate(date ? date.toISOString() : null);
    };

    // Confirme l'action en fonction de l'option choisie
    const handleConfirm = () => {
        if (postOption === 'now') {
            message.success('The post has been successfully sent!');
            onConfirm(undefined);
        } else {
            if (publishDate) {
                message.success(`The post has been scheduled for ${dayjs(publishDate).format('YYYY-MM-DD HH:mm')}!`);
                onConfirm(publishDate);
            } else {
                message.error('Please select a valid date and time to schedule the post.');
            }
        }
    };

    const disabledDate = (current: dayjs.Dayjs) => {
        const today = dayjs();
        const maxDate = today.add(6, 'month');
        return current.isBefore(today, 'day') || current.isAfter(maxDate, 'day');
    };

    return (
        <Modal
            title="Post Confirmation"
            visible={visible}
            onOk={handleConfirm}
            onCancel={onCancel}
            okText={postOption === 'now' ? "Post Now" : "Schedule"}
            cancelText="Cancel"
        >
            <Container>
                <p>This post will be published on your associated LinkedIn account. Do you want to continue?</p>
                <Radio.Group
                    value={postOption}
                    onChange={(e) => setPostOption(e.target.value)}
                    style={{ marginBottom: 16 }}
                >
                    <Radio value="now">Post Now</Radio>
                    <Radio value="schedule">Schedule for Later</Radio>
                </Radio.Group>
                {postOption === 'schedule' && (
                    <DatePicker
                        style={{ width: '100%' }}
                        showTime={{ minuteStep: 10, format: 'HH:mm' }}
                        onChange={handleDateChange}
                        disabledDate={disabledDate}
                        format="YYYY-MM-DD HH:mm"
                    />
                )}
            </Container >
        </Modal>
    );
};

export default ConfirmationModal;
