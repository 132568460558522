import React, { FormEvent, useState } from 'react';
import styled from 'styled-components';
import { message, Tooltip } from 'antd';
import Button, { ButtonVariantEnum } from '../buttons/Button';
import InputField from './InputField';
import InfoModals from './InfoModals';
import axios from 'axios';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
    width: 100%;
`;

const Message = styled.div`
    width: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    padding: 10px 20px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
`;

const Hightligth = styled.span`
    color: rgb(22, 119, 255);
    font-size: 20px;
`;

const Name = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
`;

interface RegisterFormProps {
  registerConfirmed: boolean;
  setRegisterConfirmed: (value: boolean) => void;
  toggleLogin: () => void;
}

export const RegisterForm: React.FC<RegisterFormProps> = ({
  setRegisterConfirmed,
  registerConfirmed,
  toggleLogin,
}: RegisterFormProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [infoLinkedinModalVisible, setInfoLinkedinModalVisible] = useState(false);
  const [infoOpenaiModalVisible, setInfoModalOpenaiVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const setEmailFunc = (e: string) => {
    setEmail(e);
  };

  const onPasswordChange = (password: string) => {
    setPassword(password);
    if (errorPassword) {
      setErrorPassword('');
    }
  };

  const verifyPassword = (passwordToCheck: string) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+]).{8,}$/;
    return passwordRegex.test(passwordToCheck);
  };

  const validateFields = () => {
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      messageApi.open({
        type: 'error',
        content: 'Please fill in all required fields.',
      });
      return false;
    }
    if (!verifyPassword(password)) {
      setErrorPassword('Password does not respect constraints');
      return false;
    }
    if (password !== confirmPassword) {
      messageApi.open({
        type: 'error',
        content: 'Passwords do not match.',
      });
      return false;
    }
    return true;
  };


  const handleRegister = async (e: FormEvent) => {
    e.preventDefault();
    if (!validateFields()) return;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
        firstname: firstName,
        lastname: lastName,
        email,
        password,
      });
      if (response.status === 201) {
        setRegisterConfirmed(true);
      }

    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error.response?.data?.detail || 'Error during registration. Please try again.',
      });
      console.error('Registration error:', error.response?.data);
      if (error.response?.status === 400) {
      }
    }
  };

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setRegisterConfirmed(false);
    toggleLogin();
  };

  const passwordTooltip: React.ReactNode = (
    <span>
      Your password must contain:
      <ul style={{ paddingLeft: '20px', margin: 0 }}>
        <li>At least 8 characters</li>
        <li>At least 1 uppercase letter (A-Z)</li>
        <li>At least 1 lowercase letter (a-z)</li>
        <li>At least 1 number (0-9)</li>
        <li>At least 1 special character: ! @ # $ % ^ & * ( ) _ +</li>
      </ul>
    </span>
  );

  if (registerConfirmed) {
    return (
      <div>
        <Message>
          🎉 Successfully registered! Please confirm your <Hightligth>email</Hightligth> 🎉
        </Message>
        <Button onClick={resetForm} variant={ButtonVariantEnum.PRIMARY}>Return to login page</Button>
      </div>
    );
  }

  return (
    <>
      {contextHolder}
      <Form onSubmit={handleRegister}>
        {/* <Name> */}
          <InputField
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <InputField
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        {/* </Name> */}
        <InputField
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmailFunc(e.target.value)}
          required
        />
        <Tooltip placement="top" title="Enable/disable Linkedin preview" arrow={true} mouseEnterDelay={0.2}>
          <InputField
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => onPasswordChange(e.target.value)}
            required
            tooltipInfo={passwordTooltip}
            errorMessage={errorPassword}
          ></InputField>
        </Tooltip>
        <InputField
          type="password"
          placeholder="Confirm the password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          tooltipInfo={passwordTooltip}
        />
        <Button variant={ButtonVariantEnum.PRIMARY}>Register</Button>
      </Form>
      <InfoModals
        infoLinkedinModalVisible={infoLinkedinModalVisible}
        setInfoLinkedinModalVisible={setInfoLinkedinModalVisible}
        infoOpenaiModalVisible={infoOpenaiModalVisible}
        setInfoModalOpenaiVisible={setInfoModalOpenaiVisible}
      />
    </>
  );
};

export default RegisterForm;
