import React, { FC, useContext, useEffect, useState } from 'react';
import { SubscriptionService } from '../../service/subscription.service';
import { AuthContext } from '../../auth/AuthContext';
import styled from 'styled-components';
import { SubscriptionPanel } from './SubscriptionPanel';
import { SubscriptionInvoiceApi, SubscriptionModelApi } from '../../model/SubscriptionModelApi';
import { Button, Table, TableProps, Tag } from 'antd';

interface SubscriptionProps {
  authUser: any;
}

export const Subscription: FC<SubscriptionProps> = ({ authUser }) => {
  
  const { myToken } = useContext(AuthContext);
  const [subscription, setSubscription] = useState<SubscriptionModelApi | null>(null);
  const [subscriptionInvoices, setSubscriptionInvoices] = useState<SubscriptionInvoiceApi[]>([]);
  
  useEffect(() => {
    SubscriptionService.getUserSubscription(myToken)
      .then(subscription => {
        if (subscription) {
          setSubscription(subscription);
        }
      });
  }, []);
  
  
  useEffect(() => {
    SubscriptionService.getUserSubscriptionInvoices(myToken)
      .then(invoices => {
        if (invoices) {
          console.log(invoices);
          setSubscriptionInvoices(invoices);
        }
      });
  }, [subscription]);
  
  const color = (status: string) => {
    switch (status) {
      case 'Pending':
        return 'yellow';
      case 'Paid':
        return 'green';
      case 'Refund':
        return 'geekblue';
      case 'Void':
        return 'red';
      default:
        return 'purple';
    }
  };
  
  const downloadInvoice = (invoiceId: string) => {
    const invoice = subscriptionInvoices.filter(i => i.invoiceId === invoiceId)[0];
    window.location.href = invoice.downloadInvoiceUrl!;
  };
  
  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => <span>{new Date(text).toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      })}</span>,
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <Tag color={color(status)} key={status}>
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Option',
      key: 'invoiceId',
      render: (_, invoice) => {
        if (invoice.status === 'Pending') {
          return <></>;
        }
        return (
          <Button onClick={() => downloadInvoice(invoice.key)}>Download</Button>
        );
      },
    },
  ];
  
  return (
    <Container>
      {authUser ? (
        <>
          <Cell>
            <TitleCell>Subscription</TitleCell>
            {subscription &&
              <SubscriptionPanel authUser={authUser} subscription={subscription} />
            }
          </Cell>
          <Divider><Line /></Divider>
          <Cell>
            <TitleCell>Invoice</TitleCell>
            <Columns>
              <Table<DataType> style={{ width: '100%' }} columns={columns} showHeader={false}
                               dataSource={subscriptionInvoices.map(invoice => {
                                 return {
                                   key: invoice.invoiceId,
                                   price: invoice.totalFormatted,
                                   date: invoice.createdAt,
                                   status: invoice.statusFormatted,
                                   paymentMethod: invoice.cardBrand ? `${invoice.cardBrand} ****${invoice.cardLastFour}` : '',
                                 };
                               })} />
            </Columns>
          </Cell>
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
`;

const Cell = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
`;

const TitleCell = styled.span`
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    width: 40%;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

const Columns = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
`;

interface DataType {
  key: string;
  date: string;
  status: string;
  paymentMethod: string;
  price: string;
}