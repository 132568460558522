// front/src/component/CreatePost/UploadOrTemplate/ImageUpload.tsx

import React from 'react';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Dragger } = Upload;

interface ImageUploadProps {
  onUpload: (info: any) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onUpload }) => {
  return (
    <DraggerContainer>
      <Dragger name="image" beforeUpload={() => false} onChange={onUpload} showUploadList={false}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Click or drag image to upload</p>
        <p className="ant-upload-hint">Only image files are allowed</p>
      </Dragger>
    </DraggerContainer>
  );
};

export default ImageUpload;

// Styled component for ImageUpload

const DraggerContainer = styled.div`
  flex: 1;
`;
