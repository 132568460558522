import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import LinkedinButton from '../buttons/LinkedinButton';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    judtify-content: center;
    align-items: center;
`;



interface SSOModalProps {
    visible: boolean;
    onCancel: () => void;
}

const SSOModal: React.FC<SSOModalProps> = ({ visible, onCancel }) => {

    const linkedInLogin = () => {
        window.open(`${process.env.REACT_APP_API_URL}/auth/linkedin`, '_self');
    }

    return (
        <Modal
            title="Post Confirmation"
            visible={visible}
            footer={null}
            onCancel={onCancel}
        >
            <Container>
                <p>
                    To post on LinkedIn, you need to be authenticated through LinkedIn SSO.
                    Please click the LinkedIn button below to log in.
                </p>
                <LinkedinButton text={"Sign in with LinkedIn"} onClick={linkedInLogin} />

            </Container >
        </Modal>
    );
};

export default SSOModal;
