import React, { useEffect, useState } from 'react';
import { Button, Col, ColorPicker, InputNumber, Row, Select, Slider } from 'antd';
import styled from 'styled-components';
import { PiAngle } from 'react-icons/pi';
import { TbBorderCornerPill } from 'react-icons/tb';
import { RiEyeCloseLine, RiEyeFill } from 'react-icons/ri';

const { Option } = Select;

interface SidebarProps {
  selectedShape: any;
  onChange: (updates: { [key: string]: any }) => void;
}

const SidebarContainer = styled.div`
    padding: 20px;
    background: #fff;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

const Prefix = styled.div`
    color: #b3b3b3;
    font-size: 11px;
    margin-right: 5px;
`;

const PropertySection = styled.div`
`;

const SectionTitle = styled.h3`
    font-size: 14px;
    font-weight: 500;
    color: #555;
    margin-bottom: 10px;
`;

const Divider = styled.hr`
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 5px 0;
`;

const PropertyRow = styled(Row)`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const Label = styled.label`
    font-size: 12px;
    color: #888;
    flex: 1;
`;

const ToggleButton = styled(Button) <{ active: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border: none;
    color: #C2C2C2;
`;

const fontList = [
  'Arial',
  'Times New Roman',
  'Courier New',
  'Georgia',
  'Verdana',
  'Tahoma',
  'Trebuchet MS',
  'Palatino Linotype',
  'Impact',
  'Comic Sans MS',
  'Lucida Sans Unicode',
  'Arial Black',
  'Garamond',
  'Bookman Old Style',
  'Lucida Bright',
  'Copperplate Gothic Light',
  'Gill Sans',
  'Century Gothic',
  'Franklin Gothic Medium',
  'Brush Script MT',
  'Helvetica',
];

const transformColorStopsToPickerFormat = (colorStops?: any[]) => {
  
  if (!colorStops || colorStops.length === 0) {
    return [
      { color: 'rgb(16, 142, 233)', percent: 0 },
      { color: 'rgb(135, 208, 104)', percent: 100 },
    ];
  }
  
  // Assuming colorStops is like [0, 'red', 1, 'yellow']
  const result = [];
  for (let i = 0; i < colorStops.length; i += 2) {
    result.push({
      percent: colorStops[i] * 100,
      color: colorStops[i + 1] as string,
    });
  }
  
  return result;
};

const PropertiesPanel: React.FC<SidebarProps> = ({ selectedShape, onChange }) => {
  // Initialisation du hook useState en dehors de toute condition
  const [fillPickerValue, setFillPickerValue] = useState<any>(
    selectedShape?.isGradientFill
      ? transformColorStopsToPickerFormat(
        selectedShape.gradientType === 'linear'
          ? selectedShape.fillLinearGradientColorStops
          : selectedShape.fillRadialGradientColorStops,
      )
      : selectedShape?.fill || 'rgba(0, 0, 0, 1)', // Default value if selectedShape is undefined
  );
  
  const [gradientType, setGradientType] = useState<string>('linear');
  
  const handleColorChange = (value: any) => {
    if (value?.colors && Array.isArray(value.colors) && value.colors.length > 0) {
      // Assuming value.colors is like [{color: 'rgba(...)', percent: 0}, {...}]
      const colorStops = value.colors.reduce((acc: any[], stop: any) => {
        if (stop.color) {
          acc.push(stop.percent / 100, stop.color.toHexString());
        }
        return acc;
      }, []);
      
      if (gradientType === 'linear') {
        onChange({
          isGradientFill: true,
          fillLinearGradientColorStops: colorStops,
          fillLinearGradientStartPoint: { x: 0, y: 0 },
          fillLinearGradientEndPoint: { x: 50, y: 50 },
        });
      } else if (gradientType === 'radial') {
        onChange({
          isGradientFill: true,
          fillRadialGradientColorStops: colorStops,
          fillRadialGradientStartPoint: { x: 0, y: 0 },
          fillRadialGradientStartRadius: 0,
          fillRadialGradientEndPoint: { x: 50, y: 50 },
          fillRadialGradientEndRadius: 100,
        });
      }
    } else if (value?.metaColor) {
      // If a solid color is picked
      const { r, g, b, a } = value.metaColor;
      const color = `rgba(${r}, ${g}, ${b}, ${a})`;
      onChange({ isGradientFill: false, fill: color });
    }
  };
  
  
  // Utilisation du hook useEffect en dehors de toute condition
  useEffect(() => {
    
    if (selectedShape) {
      setFillPickerValue(
        selectedShape.isGradientFill
          ? transformColorStopsToPickerFormat(
            selectedShape.gradientType === 'linear'
              ? selectedShape.fillLinearGradientColorStops
              : selectedShape.fillRadialGradientColorStops,
          )
          : selectedShape.fill,
      );
      if (selectedShape.isGradientFill) {
        setGradientType(selectedShape.gradientType || 'linear');
      }
    }
  }, [selectedShape]);
  
  // Rendu du composant
  if (!selectedShape) {
    return <SidebarContainer>Aucune forme sélectionnée</SidebarContainer>;
  }
  
  const handleGradientTypeChange = (value: string) => {
    const updates: { [key: string]: any } = { gradientType: value };
    
    if (value === 'linear' && selectedShape.fillRadialGradientColorStops) {
      // Switching to linear, transfer radial gradient properties to linear
      updates.fillLinearGradientColorStops = selectedShape.fillRadialGradientColorStops;
      updates.fillLinearGradientStartPoint = selectedShape.fillRadialGradientStartPoint || { x: 0, y: 0 };
      updates.fillLinearGradientEndPoint = selectedShape.fillRadialGradientEndPoint || { x: 50, y: 50 };
      
    } else if (value === 'radial' && selectedShape.fillLinearGradientColorStops) {
      // Switching to radial, transfer linear gradient properties to radial
      updates.fillRadialGradientColorStops = selectedShape.fillLinearGradientColorStops;
      updates.fillRadialGradientStartPoint = selectedShape.fillLinearGradientStartPoint || { x: 0, y: 0 };
      updates.fillRadialGradientEndPoint = selectedShape.fillLinearGradientEndPoint || { x: 0, y: 0 };
      updates.fillRadialGradientStartRadius = 0;
      updates.fillRadialGradientEndRadius = 70;
      
    }
    
    setGradientType(value);
    onChange(updates);
  };
  
  
  return (
    <SidebarContainer>
      <PropertySection>
        <PropertyRow>
          <Col span={11}>
            <InputNumber
              value={selectedShape.x}
              prefix={<Prefix>X</Prefix>}
              onChange={(value) => onChange({ x: value })}
              style={{ width: '100%' }}
              precision={2}
              step={5}
            />
          </Col>
          <Col span={11}>
            <InputNumber
              value={selectedShape.y}
              prefix={<Prefix>Y</Prefix>}
              onChange={(value) => onChange({ y: value })}
              style={{ width: '100%' }}
              precision={2}
            />
          </Col>
        </PropertyRow>
        <PropertyRow>
          <Col span={11}>
            <InputNumber
              value={selectedShape.width}
              prefix={<Prefix>L</Prefix>}
              onChange={(value) => onChange({ width: value })}
              style={{ width: '100%' }}
              precision={2}
            />
          </Col>
          <Col span={11}>
            <InputNumber
              value={selectedShape.height}
              prefix={<Prefix>H</Prefix>}
              onChange={(value) => onChange({ height: value })}
              style={{ width: '100%' }}
              precision={2}
            />
          </Col>
        </PropertyRow>
        <PropertyRow>
          <Col span={11}>
            <InputNumber
              min={0}
              max={360}
              prefix={<Prefix><PiAngle /></Prefix>}
              value={selectedShape.rotation || 0}
              onChange={(value) => onChange({ rotation: value })}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={11}>
            <InputNumber
              min={0}
              max={100}
              prefix={<Prefix><TbBorderCornerPill /></Prefix>}
              value={selectedShape.cornerRadius || 0}
              onChange={(value) => onChange({ cornerRadius: value })}
              style={{ width: '100%' }}
            />
          </Col>
        </PropertyRow>
      </PropertySection>
      
      <Divider />
      
      <PropertySection>
        <SectionTitle>Fill</SectionTitle>
        <PropertyRow>
          <Col flex={2}>
            <ColorPicker
              value={fillPickerValue}
              onChange={handleColorChange}
              showText
              mode={['single', 'gradient']}
              panelRender={(panel) => (
                <div className="custom-panel">
                  {panel}
                  {selectedShape.isGradientFill && (
                    <Select
                      value={gradientType}
                      onChange={handleGradientTypeChange}
                      style={{ width: '100%', marginTop: 10 }}
                    >
                      <Option value="linear">Linear</Option>
                      <Option value="radial">Radial</Option>
                    </Select>
                  )}
                </div>
              )}
            />
          </Col>
          <Col flex={2}>
            <InputNumber
              min={0}
              max={100}
              value={(selectedShape.opacity ?? 1) * 100}
              onChange={(value) => value !== null && onChange({ opacity: value / 100 })}
              formatter={(value) => `${value}%`}
              parser={(value) => value ? parseFloat(value.replace('%', '')) : 0}
              style={{ height: '100%' }}
            />
          </Col>
          <Col flex={1}>
            <ToggleButton
              active={selectedShape.visible ?? true}
              onClick={() => onChange({ visible: !selectedShape.visible })}
            >
              {selectedShape.visible ? <RiEyeFill /> : <RiEyeCloseLine />}
            </ToggleButton>
          </Col>
        </PropertyRow>
      </PropertySection>
      {selectedShape.isGradientFill && gradientType === 'linear' && (
        <PropertySection>
          <SectionTitle>Linear Gradient Points</SectionTitle>
          <PropertyRow>
            <Col span={11}>
              <InputNumber
                prefix={<Prefix>Start X</Prefix>}
                value={selectedShape.fillLinearGradientStartPoint?.x}
                onChange={(value) =>
                  onChange({
                    fillLinearGradientStartPoint: {
                      ...selectedShape.fillLinearGradientStartPoint,
                      x: value,
                    },
                  })
                }
                style={{ width: '100%' }}
                precision={2}
              />
            </Col>
            <Col span={11}>
              <InputNumber
                prefix={<Prefix>Start Y</Prefix>}
                value={selectedShape.fillLinearGradientStartPoint?.y}
                onChange={(value) =>
                  onChange({
                    fillLinearGradientStartPoint: {
                      ...selectedShape.fillLinearGradientStartPoint,
                      y: value,
                    },
                  })
                }
                style={{ width: '100%' }}
                precision={2}
              />
            </Col>
          </PropertyRow>
          <PropertyRow>
            <Col span={11}>
              <InputNumber
                prefix={<Prefix>End X</Prefix>}
                value={selectedShape.fillLinearGradientEndPoint?.x}
                onChange={(value) =>
                  onChange({
                    fillLinearGradientEndPoint: {
                      ...selectedShape.fillLinearGradientEndPoint,
                      x: value,
                    },
                  })
                }
                style={{ width: '100%' }}
                precision={2}
              />
            </Col>
            <Col span={11}>
              <InputNumber
                prefix={<Prefix>End Y</Prefix>}
                value={selectedShape.fillLinearGradientEndPoint?.y}
                onChange={(value) =>
                  onChange({
                    fillLinearGradientEndPoint: {
                      ...selectedShape.fillLinearGradientEndPoint,
                      y: value,
                    },
                  })
                }
                style={{ width: '100%' }}
                precision={2}
              />
            </Col>
          </PropertyRow>
        </PropertySection>
      )}
      
      {selectedShape.isGradientFill && gradientType === 'radial' && (
        <PropertySection>
          <SectionTitle>Radial Gradient Points</SectionTitle>
          <PropertyRow>
            <Col span={11}>
              <InputNumber
                prefix={<Prefix>Start X</Prefix>}
                value={selectedShape.fillRadialGradientStartPoint?.x}
                onChange={(value) =>
                  onChange({
                    fillRadialGradientStartPoint: {
                      ...selectedShape.fillRadialGradientStartPoint,
                      x: value,
                    },
                  })
                }
                style={{ width: '100%' }}
                precision={2}
              />
            </Col>
            <Col span={11}>
              <InputNumber
                prefix={<Prefix>Start Y</Prefix>}
                value={selectedShape.fillRadialGradientStartPoint?.y}
                onChange={(value) =>
                  onChange({
                    fillRadialGradientStartPoint: {
                      ...selectedShape.fillRadialGradientStartPoint,
                      y: value,
                    },
                  })
                }
                style={{ width: '100%' }}
                precision={2}
              />
            </Col>
          </PropertyRow>
          <PropertySection>
            <PropertyRow>
              <Col span={11}>
                <InputNumber
                  prefix={<Prefix>End X</Prefix>}
                  value={selectedShape.fillRadialGradientEndPoint?.x}
                  onChange={(value) =>
                    onChange({
                      fillRadialGradientEndPoint: {
                        ...selectedShape.fillRadialGradientEndPoint,
                        x: value,
                      },
                    })
                  }
                  style={{ width: '100%' }}
                  precision={2}
                />
              </Col>
              <Col span={11}>
                <InputNumber
                  prefix={<Prefix>End Y</Prefix>}
                  value={selectedShape.fillRadialGradientEndPoint?.y}
                  onChange={(value) =>
                    onChange({
                      fillRadialGradientEndPoint: {
                        ...selectedShape.fillRadialGradientEndPoint,
                        y: value,
                      },
                    })
                  }
                  style={{ width: '100%' }}
                  precision={2}
                />
              </Col>
            </PropertyRow>
          </PropertySection>
          <PropertyRow>
            <Col span={11}>
              <InputNumber
                prefix={<Prefix>Start Radius</Prefix>}
                value={selectedShape.fillRadialGradientStartRadius || 0}
                onChange={(value) =>
                  onChange({ fillRadialGradientStartRadius: value })
                }
                style={{ width: '100%' }}
                precision={2}
              />
            </Col>
            <Col span={11}>
              <InputNumber
                prefix={<Prefix>End Radius</Prefix>}
                value={selectedShape.fillRadialGradientEndRadius || 70}
                onChange={(value) =>
                  onChange({ fillRadialGradientEndRadius: value })
                }
                style={{ width: '100%' }}
                precision={2}
              />
            </Col>
          </PropertyRow>
        </PropertySection>
      )
      }
      
      <Divider />
      
      <PropertySection>
        <SectionTitle>Stroke</SectionTitle>
        <PropertyRow>
          <Col span={11}>
            <ColorPicker
              value={selectedShape.stroke || '#000000'}
              onChange={(value) => onChange({ stroke: value.toHexString() })}
              showText
            />
          </Col>
          <Col span={11}>
            <InputNumber
              min={0}
              max={20}
              value={selectedShape.strokeWidth || 0}
              onChange={(value) => onChange({ strokeWidth: value })}
            />
          </Col>
        </PropertyRow>
      </PropertySection>
      
      {(selectedShape.typeShape === 'text' || selectedShape.typeShape === 'textAI') && (
        <>
          <Divider />
          <PropertySection>
            <SectionTitle>Text Properties</SectionTitle>
            <PropertyRow>
              <Col span={11}>
                <Label>Font Size</Label>
                <InputNumber
                  value={selectedShape.fontSize || 24}
                  onChange={(value) => onChange({ fontSize: value })}
                  style={{ width: '100%' }}
                  min={1}
                  max={100}
                />
              </Col>
              <Col span={11}>
                <Label>Line Height</Label>
                <InputNumber
                  value={selectedShape.lineHeight || 1}
                  onChange={(value) => onChange({ lineHeight: value })}
                  style={{ width: '100%' }}
                  min={1}
                  max={3}
                  step={0.1}
                />
              </Col>
            </PropertyRow>
            <PropertyRow>
              <Col span={11}>
                <Label>Font Family</Label>
                <Select
                  value={selectedShape.fontFamily || 'Arial'}
                  onChange={(value) => onChange({ fontFamily: value })}
                  style={{ width: '100%' }}
                >
                  {fontList.map((font) => (
                    <Option key={font} value={font}>
                      {font}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={11}>
                <Label>Font Style</Label>
                <Select
                  value={selectedShape.fontStyle || 'normal'}
                  onChange={(value) => onChange({ fontStyle: value })}
                  style={{ width: '100%' }}
                >
                  <Option value="normal">Normal</Option>
                  <Option value="italic">Italic</Option>
                  <Option value="bold">Bold</Option>
                </Select>
              </Col>
            </PropertyRow>
            <PropertyRow>
              <Col span={11}>
                <Label>Alignment</Label>
                <Select
                  value={selectedShape.align || 'left'}
                  onChange={(value) => onChange({ align: value })}
                  style={{ width: '100%' }}
                >
                  <Option value="left">Left</Option>
                  <Option value="center">Center</Option>
                  <Option value="right">Right</Option>
                </Select>
              </Col>
            </PropertyRow>
          </PropertySection>
        </>
      )}
      
      <Divider />
      
      <PropertySection>
        <SectionTitle>Shadow</SectionTitle>
        <PropertyRow>
          <Col span={24}>
            <Label>shadow blur:</Label>
            <Slider
              min={0}
              max={50}
              value={selectedShape.shadowBlur || 0}
              onChange={(value) => onChange({ shadowBlur: value })}
            />
          </Col>
        </PropertyRow>
      </PropertySection>
    </SidebarContainer>
  );
};

export default PropertiesPanel;
