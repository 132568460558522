// front/src/component/CreatePost/UploadOrTemplate/UploadOrTemplate.tsx

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button as AntButton, Carousel, message, Spin } from 'antd';


import {
  Container,
  Result,
  ImageImportContainer,
  ImportImageSeparation,
  TemplateContainer,
  BetaTag,
  TemplateContainerIcon,
  OneToOneOutlinedIcon,
  TemplateContainerText,
  TemplateContainerTitle,
  TemplateContainerSubTitle,
  PlusOutlinedIcon,
  StyledCarousel,
  Slide,
} from './StyledComponents';

import TemplateModal from './TemplateModal';
import ImageUpload from './ImageUpload';
import ImageBankButton from './ImageBankButton';
import { AuthContext } from '../../../auth/AuthContext';
import { convertKonvaObjectsToHTML, filterTextAI, generateElementComponent, replaceTextAIContent } from '../../../utils/template';
import ImageBankModal from '../ImageBankModal';
import { TextAIInfo } from '../../../types';

interface UploadOrTemplateProps {
  image: string | null;
  setImageUpload: (image: string | null) => void;
  setImageUrl: (image: string | null) => void;
  template: any | null;
  setTemplate: (template: any | null) => void;
  setTemplatePipe: (template: any) => void;
  templateContent: any;
  isTextEmpty: boolean;
  contentPost: string;
  setHtmlContent: (html: any) => void;
}

const UploadOrTemplate: React.FC<UploadOrTemplateProps> = ({
  image,
  setImageUpload,
  setImageUrl,
  template,
  setTemplate,
  setTemplatePipe,
  templateContent,
  isTextEmpty,
  contentPost,
  setHtmlContent,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<any[]>([]);
  const [jsonDataSave, setJsonDataSave] = useState<any>(null);
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const [isImageBankModalVisible, setIsImageBankModalVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  const [isView, setIsView] = useState(false);
  const { myToken } = useContext(AuthContext);

  useEffect(() => {
      // console.log('template', template);
      // console.log('templateContent', templateContent);
      // console.log('selectedTemplate', selectedTemplate);
      console.log('jsonDataSave', jsonDataSave);
  }, [selectedTemplate, jsonDataSave]);

  // Handle text changes in 'textAI' elements
  const handleTextChange = (event: any, id: string) => {
    const newText = event.target.value;
    const newJson = jsonDataSave.map((slide: any) =>
      slide.map((item: any) => {
        if (item.id === id) {
          return { ...item, text: newText };
        }
        return item;
      })
    );

    setJsonDataSave(newJson);
    setHtmlContent(convertKonvaObjectsToHTML(newJson))
  };

  // Update selected template when jsonDataSave changes
  useEffect(() => {
    if (jsonDataSave) {
      setSelectedTemplate(jsonDataSave);
    }
  }, [jsonDataSave]);

  // Handle image preview
  useEffect(() => {
    if (image) {
      setPreviewUrl(image);
    } else {
      setPreviewUrl(undefined);
    }
  }, [image]);

  // Handle template selection
  const showTemplateModal = () => {
    setIsTemplateModalVisible(true);
  };

  const handleTemplateModalCancel = () => {
    setIsTemplateModalVisible(false);
  };

  const handleTemplateSelect = (template: any) => {
    setTemplate(template);
    setIsTemplateModalVisible(false);
    fetch(`${process.env.REACT_APP_API_URL}/post-templates/${template.id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    })
      .then((response) => response.json())
      .then((templateData: any) => {
        const jsonData = templateData.jsonData ? JSON.parse(templateData.jsonData) : [[]];
        setJsonDataSave(jsonData);
        setSelectedTemplate(jsonData);
        setHtmlContent(convertKonvaObjectsToHTML(jsonData));
        const pipe: TextAIInfo[] = filterTextAI(jsonData);
        setTemplatePipe(pipe);
      })
      .catch((error) => console.error('Error fetching template content:', error));
  };

  // Handle image bank selection
  const showImageBankModal = () => {
    setIsImageBankModalVisible(true);
  };

  const selectImageFromImageBank = (imageUrl: string) => {
    setPreviewUrl(imageUrl);
    setImageUrl(imageUrl);
    setImageUpload(null);
    setIsImageBankModalVisible(false);
  };

  // Handle image upload
  const handleImageUpload = (info: any) => {
    const file = info.fileList[0].originFileObj;
    const isImage = file.type.startsWith('image/');
    const isUnder5MB = file.size / 1024 / 1024 < 5;
    if (!isImage) {
      message.error('You can only upload image files!');
      return;
    }
    if (!isUnder5MB) {
      message.error('Image must be smaller than 5MB!');
      return;
    }
    setImageUpload(file);
  };

  const handleImageRemove = () => {
    setImageUpload(null);
    setImageUrl(null);
  };

  // Handle template content after generation
  useEffect(() => {
    if (templateContent) {
      const json_tmp = replaceTextAIContent(jsonDataSave, templateContent);
      setJsonDataSave(json_tmp);
      setSelectedTemplate(json_tmp);
      setHtmlContent(convertKonvaObjectsToHTML(json_tmp));
    }
  }, [templateContent]);

  // Handle view state based on post content
  useEffect(() => {
    if (contentPost.trim() === '') {
      setIsView(false);
    }
  }, [contentPost]);

  return (
    <Container>
      {image || selectedTemplate.length > 0 ? (
        image ? (
          <Result>
            <img src={previewUrl} alt="uploaded" style={{ width: '100%' }} />
            <AntButton onClick={handleImageRemove}>Remove Image</AntButton>
          </Result>
        ) : (
          <Result>
            {selectedTemplate.length > 0 ? (
              <StyledCarousel draggable={true} arrows infinite={false}>
                {selectedTemplate.map((slideItems, index) => (
                  <Slide key={index}>
                    <div
                      className="carousel-slide"
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {slideItems.map((item: any) =>
                        generateElementComponent(item, handleTextChange)
                      )}
                    </div>
                  </Slide>
                ))}
              </StyledCarousel>
            ) : (
              <Spin />
            )}
            <AntButton
              onClick={() => {
                setSelectedTemplate([]);
                setTemplatePipe(null);
              }}
            >
              Remove Template
            </AntButton>
          </Result>
        )
      ) : isTextEmpty || isView ? (
        <>
          <ImageImportContainer>
            <ImageUpload onUpload={handleImageUpload} />
            <ImportImageSeparation />
            <ImageBankButton onClick={showImageBankModal} />
          </ImageImportContainer>

          <TemplateContainer onClick={showTemplateModal}>
            <BetaTag>Beta</BetaTag>
            <TemplateContainerIcon>
              <OneToOneOutlinedIcon />
            </TemplateContainerIcon>
            <TemplateContainerText>
              <TemplateContainerTitle>Select a Template</TemplateContainerTitle>
              <TemplateContainerSubTitle>Choose from your saved templates</TemplateContainerSubTitle>
            </TemplateContainerText>
          </TemplateContainer>
        </>
      ) : (
        <TemplateContainer onClick={() => setIsView(true)}>
          <TemplateContainerText>
            <PlusOutlinedIcon />
          </TemplateContainerText>
        </TemplateContainer>
      )}

      <ImageBankModal
        acceptImage={selectImageFromImageBank}
        token={myToken}
        visible={isImageBankModalVisible}
        setVisible={setIsImageBankModalVisible}
      />

      <TemplateModal
        visible={isTemplateModalVisible}
        onCancel={handleTemplateModalCancel}
        onSelect={handleTemplateSelect}
      />
    </Container>
  );
};

export default UploadOrTemplate;
