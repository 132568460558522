import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, InputRef } from 'antd';

// Wrapper pour positionner l'input et le span caché
const StyledInputWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

// Styles pour l'input
const StyledInput = styled(Input)`
  width: auto;
  max-width: 50ch; // Largeur maximale de 50 caractères
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 0;
  margin: 0;

  &:focus,
  &:hover {
    background-color: transparent;
    border-color: #fff;
    box-shadow: none;
  }

  input {
    background-color: transparent;
    color: #fff;
    padding: 0;
    margin: 0;
  }
`;

// Span caché pour mesurer la largeur du texte
const HiddenSpan = styled.span`
  visibility: hidden;
  white-space: pre;
  position: absolute;
  top: 0;
  left: 0;
  font-size: inherit;
  font-family: inherit;
`;

interface AutoSizeInputProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    maxWidth?: number | string;
}

const AutoSizeInput: React.FC<AutoSizeInputProps> = ({
    value,
    onChange,
    placeholder,
    maxWidth = '50ch', // Définit la largeur maximale à 50 caractères
}) => {
    const spanRef = useRef<HTMLSpanElement>(null);
    const inputRef = useRef<InputRef>(null);
    const [inputWidth, setInputWidth] = useState<number>(0);

    useEffect(() => {
        if (spanRef.current) {
            const newWidth = spanRef.current.offsetWidth + 10; // Ajouter un peu de padding
            setInputWidth(newWidth);
        }
    }, [value]);

    return (
        <StyledInputWrapper>
            <StyledInput
                ref={inputRef}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                style={{ width: inputWidth, maxWidth }}
                maxLength={60}
            />
            <HiddenSpan ref={spanRef}>{value || placeholder}</HiddenSpan>
        </StyledInputWrapper>
    );
};

export default AutoSizeInput;
