import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import { DesktopOutlined, DatabaseOutlined, GlobalOutlined, CodeOutlined, AppstoreOutlined, LinkedinFilled } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import linkedinAlgorithm from '../tutorialPages/linkedinAlgorithm';
import createPost from '../tutorialPages/createPost';
import templateTips from '../tutorialPages/templateTips';

const { Title, Text } = Typography;

// Types pour les tutoriels
type Tutorial = {
    id: string;
    title: string;
    content: string;
    category: string;
};

// Données des tutoriels


const tutorials = [linkedinAlgorithm, createPost, templateTips];
// Catégories avec leurs icônes
const categories = [
    { name: 'Linkedin', icon: LinkedinFilled },
    { name: 'Create Post', icon: AppstoreOutlined },
    { name: 'Template', icon: DatabaseOutlined },
];

// Styled Components
const AppContainer = styled.div`
  min-height: 100vh;
  background-color: #f9fafb;
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.header`
  background-color: #028CB5;
  color: white;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 70px;
`;

const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 1.5rem;
  height: calc(100% - 86px);
`;

const Sidebar = styled.div`
  width: 16rem;
  flex-shrink: 0;
`;

const SidebarContainer = styled.div`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
`;

const SidebarTitle = styled(Title)`
  && {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
`;

const CategoryButton = styled(Button) <{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  justify-content: start;
  font-size: 0.875rem;
  color: ${(props) => (props.isSelected ? '#028CB5' : 'inherit')};
  background-color: ${(props) => (props.isSelected ? '#CCE8F0' : 'transparent')};
  border: none;
  text-align: left;
  box-shadow: none;

  &:hover {
    background-color: #028CB5;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;

  overflow-y: auto;
  height: 100%;
    &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const TutorialTitle = styled(Title)`
  && {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #1f2937;
  }
`;

const TutorialCategory = styled(Text)`
  && {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 1rem;
  }
`;

const TutorialContent = styled.div`
  color: #4b5563;
  line-height: 1.75;

  img {
    display: block;
    max-width: 60%;
    margin: 1rem auto;
  }

    /* Style des listes */
  ul,
  ol {
    padding-left: 2rem; /* Ajout de padding pour les listes */
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  /* Style des éléments de liste */
  li {
    margin-bottom: 0.5rem; /* Espacement entre les éléments */
  }
`;

const TutorialList = styled.div`
  margin-top: 2rem;
  border-top: 1px solid #e5e7eb;
  padding-top: 1rem;
`;

const TutorialButton = styled(Button) <{ isSelected: boolean }>`
  display: flex;
  width: 100%;
  text-align: left;
  padding: 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: ${(props) => (props.isSelected ? '#028CB5' : 'inherit')};
  background-color: ${(props) => (props.isSelected ? '#CCE8F0' : 'transparent')};
  border: none;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: start;
  box-shadow: none;

  &:hover {
    background-color: #028CB5;
  }
`;

export const TutorialPage = () => {
    const [selectedCategory, setSelectedCategory] = useState<string>('Linkedin');
    const [selectedTutorial, setSelectedTutorial] = useState<Tutorial>(tutorials[0]);

    const filteredTutorials = tutorials.filter(
        (tutorial) => tutorial.category === selectedCategory
    );

    return (
        <AppContainer>
            <Header>
                <DesktopOutlined style={{ fontSize: '1.5rem', color: 'white' }} />
                <Title level={2} style={{ margin: 0, color: 'white' }}>
                    Tutorials
                </Title>
            </Header>

            <MainContainer>
                {/* Sidebar */}
                <Sidebar>
                    <SidebarContainer>
                        <SidebarTitle level={4}>Catégories</SidebarTitle>
                        <nav>
                            {categories.map((category) => {
                                const Icon = category.icon;
                                return (
                                    <CategoryButton
                                        key={category.name}
                                        onClick={() => setSelectedCategory(category.name)}
                                        isSelected={selectedCategory === category.name}
                                    >
                                        <Icon />
                                        {category.name}
                                    </CategoryButton>
                                );
                            })}
                        </nav>
                    </SidebarContainer>
                </Sidebar>

                {/* Main Content */}
                <ContentContainer>
                    <TutorialTitle>{selectedTutorial.title}</TutorialTitle>
                    <TutorialCategory>Catégorie: {selectedTutorial.category}</TutorialCategory>
                    <TutorialContent><ReactMarkdown>{selectedTutorial.content}</ReactMarkdown></TutorialContent>

                    <TutorialList>
                        <Title level={5}>Autres tutoriels dans {selectedCategory}</Title>
                        <div>
                            {filteredTutorials.map((tutorial) => (
                                <TutorialButton
                                    key={tutorial.id}
                                    onClick={() => setSelectedTutorial(tutorial)}
                                    isSelected={selectedTutorial.id === tutorial.id}
                                >
                                    {tutorial.title}
                                </TutorialButton>
                            ))}
                        </div>
                    </TutorialList>
                </ContentContainer>
            </MainContainer>
        </AppContainer>
    );
}

