// front/src/component/CreatePost/UploadOrTemplate/ImageBankButton.tsx

import React from 'react';
import styled from 'styled-components';
import { PictureOutlined } from '@ant-design/icons';

interface ImageBankButtonProps {
  onClick: () => void;
}

const ImageBankButton: React.FC<ImageBankButtonProps> = ({ onClick }) => {
  return (
    <ImageBankContainer onClick={onClick}>
      <ImageBankContainerIcon>
        <UploadOutlinedIcon />
      </ImageBankContainerIcon>
      <ImageBankContainerTitle>Find random picture by keyword</ImageBankContainerTitle>
      <ImageBankContainerSubTitle>Free of use images</ImageBankContainerSubTitle>
    </ImageBankContainer>
  );
};

export default ImageBankButton;

// Styled components for ImageBankButton

const ImageBankContainer = styled.div`
  flex: 1;
  text-align: center;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px;

  &:hover {
    border-color: #028cb5;
  }
`;

const ImageBankContainerIcon = styled.p`
  font-size: 14px;
  margin: 10px;
  text-align: center;
  font-weight: 600;
`;

const UploadOutlinedIcon = styled(PictureOutlined)`
  color: #028cb5;
  font-size: 48px;
`;

const ImageBankContainerTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  padding: 0 10px 4px 10px;
  color: rgba(0, 0, 0, 0.88);
`;

const ImageBankContainerSubTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
`;
