import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Product } from '../../types';
import axios from 'axios';
import { AuthContext } from '../../auth/AuthContext';
import { useNavigate} from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
`;

const ProductCard = styled.div`
  border: 1px solid rgb(240, 240, 240);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  font-size: 12px;
  padding: 10px;
  color: #22222;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

  &:hover {
    background-color: #f8f8f8;
    color: rgb(2, 140, 181);
    border: 1px solid rgb(2, 140, 181);
  }
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const ContentParagraph = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Adjust the number of lines as needed */
  -webkit-box-orient: vertical;
`;

interface ProductProps{
    onSelectProductFunc:any;
}


const ProductSubject: React.FC<ProductProps> = ({onSelectProductFunc}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const { myToken } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/company-offers/user`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
          },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [myToken]);


  return (
    <PageContainer>
          <ProductCard key="addButton" 
            onClick={() => navigate("/product")}
            style={{height: "39px"}}
            >
            <ContentContainer style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
              <ContentParagraph>
                <PlusOutlined/>
              </ContentParagraph>
            </ContentContainer>
          </ProductCard>
        {products.map((product:Product) => (
          <ProductCard key={product.id} onClick={() => {onSelectProductFunc(product)}}>
            <ContentContainer>
              <ContentParagraph>
                {product.title}
              </ContentParagraph>
            </ContentContainer>
          </ProductCard>
        ))}
    </PageContainer>
  );
};

export default ProductSubject;
