import React, { useContext, useEffect, useState } from 'react';
import { Calendar, message } from 'antd';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { ScheduledPost } from '../types';
import PostList from '../component/scheduler/PostList';
// import Programmer from '../component/scheduler/Programmer';
import { Preview } from '../component/scheduler/PreviewContainer';
import { AuthContext } from '../auth/AuthContext';
import ConfirmationModal from '../component/CreatePost/ConfirmationModal';
import { LeftContainer } from '../component/CreatePost/Styles';

const Container = styled.div`
    display: flex;
    padding: 50px; /* Réduit le padding pour éviter le débordement */
    justify-content: center;
    align-items: stretch; /* Permet aux enfants de s'étendre pour occuper toute la hauteur disponible */
    height: 100vh; /* Hauteur totale de la fenêtre */
    width: 100%;
    gap: 30px; /* Réduit l'espacement entre les conteneurs */
    box-sizing: border-box; /* Inclut le padding dans la hauteur totale */
    overflow: hidden; /* Empêche tout débordement visible */

    @media (max-width: 768px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      height: auto;
      padding: 10px 10px;
    }
`;

const CalendarContainer = styled.div`
    //flex: 0 0 30%; /* Prend 60% de la largeur disponible */
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e4e2df;
    padding: 16px;
    box-sizing: border-box;
    overflow: hidden; /* Empêche le débordement du contenu */
    gap: 5px;
`;

const PostContainer = styled.div`
    flex: 1; /* Prend le reste de la largeur disponible */
    padding: 16px;
    display: flex;
    gap: 10px;
    flex-direction: column;
`;

// const ProgramationContainer = styled.div`
//     flex: 1; /* Prend le reste de la largeur disponible */
//     padding: 16px;
//     overflow-y: auto; /* Ajoute un défilement si le contenu dépasse */
//     background: #fff;
//     border-radius: 8px;
//     border: 1px solid #e4e2df;
//     box-sizing: border-box;

//     /* Éviter les marges internes sur le titre */

//     & > h2 {
//         margin-top: 0;
//         margin-bottom: 16px;
//     }
// `;

const StyledCalendar = styled(Calendar)`
    flex: 1; /* Permet au calendrier de s'étendre dans le conteneur flex */
    width: 100%;
    height: 100%;

    .ant-picker-calendar-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    table {
        width: 100%;
        height: 100%;
    }

    /* Supprimer les marges internes si nécessaire */

    .ant-picker-calendar-header {
        padding: 0;
        margin-bottom: 16px; /* Ajoute un espace entre l'en-tête et le contenu */
    }

    .ant-picker-calendar-date {
        position: relative; /* Pour positionner les badges */
    }

    /* Style pour les badges d'événements */

    .event-badge {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 6px;
        height: 6px;
        background-color: #028CB5;
        border-radius: 50%;
    }
`;

const Title = styled.div`
    color: #727272;
    font-size: 16px;
    font-weight: 400;
`;

const Separator = styled.div`
    background: #e4e2df;
    height: 1px;
    width: 100%;
`;

const SchedulerPage: React.FC = () => {
  const { myToken } = useContext(AuthContext);
  const [contentPost, setContentPost] = useState('');
  const [imagePostUploaded, setImagePostUploaded] = useState<File | null>(null);
  const [imagePostUrl, setImagePostUrl] = useState<string>('');
  const [posts, setPosts] = useState<ScheduledPost[]>([]);
  const [template, setTemplate] = useState<any | null>(null);
  const [templatePipe, setTemplatePipe] = useState<any | null>(null);
  const [templateContent, setTemplateContent] = useState<any | null>(null);
  const [htmlContent, setHtmlContent] = useState<any | null>(null);
  const [selectPosts, setSelectPosts] = useState<ScheduledPost | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>(
    new Date().toISOString().split('T')[0],
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const fetchScheduledPosts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/linkedin/posts?pageNumber=0&pageSize=30&filterScheduledPost=true`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${myToken}`,
        },
      });
      
      if (!response.ok) {
        throw new Error(`Erreur HTTP ! statut : ${response.status}`);
      }
      
      const data: ScheduledPost[] = await response.json();
      // Convertir scheduleDate au format 'YYYY-MM-DD' pour la cohérence
      const formattedPosts = data.map(post => ({
        ...post,
        date: dayjs(post.scheduleDate).format('YYYY-MM-DD'),
      }));
      setPosts(formattedPosts);
    } catch (error) {
      console.error('Échec de la récupération des posts programmés :', error);
    }
  };
  
  useEffect(() => {
    fetchScheduledPosts();
  }, []);
  
  const onSelectDate = (date: Dayjs) => {
    setSelectedDate(date.format('YYYY-MM-DD'));
  };
  
  const dateCellRender = (value: Dayjs) => {
    const formattedDate = value.format('YYYY-MM-DD');
    const hasPosts = posts.some(post => post.date === formattedDate);
    
    return hasPosts ? <div className="event-badge"></div> : null;
  };
  
  const OnSelectPost = (post: ScheduledPost) => {
    setSelectPosts(post);
  };
  
  useEffect(() => {
    if (selectPosts) {
      if (selectPosts.images && selectPosts.images.length > 0) {
        const imageUrl = selectPosts.images[0].url;
        setImagePostUrl(imageUrl);
      } else {
        setImagePostUrl('');
      }
      setContentPost(selectPosts.content);
    }
  }, [selectPosts]);
  
  useEffect(() => {
    if (imagePostUploaded) {
      setImagePostUrl(URL.createObjectURL(imagePostUploaded));
    }
  }, [imagePostUploaded]);
  
  const OnDeletePost = async () => {
    if (selectPosts === null) {
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/linkedin/posts/${selectPosts.id}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${myToken}`,
        },
      });
      
      if (!response.ok) {
        throw new Error(`Erreur HTTP ! statut : ${response.status}`);
      }
      
      const updatedPosts = posts.filter(post => post.id !== selectPosts.id);
      setPosts(updatedPosts);
      setSelectPosts(null);
      setContentPost('');
      setImagePostUrl('');
      setImagePostUploaded(null);
    } catch (error) {
      console.error('Échec de la suppression du post programmé :', error);
    }
  };
  
  const onUpdate = async () => {
    if (selectPosts === null) {
      return;
    }
    if (contentPost) {
      const formData = new FormData();
      formData.append('content', contentPost);
      formData.append('linkedinPostId', selectPosts.id);
      formData.append('scheduleDate', selectPosts.scheduleDate);
      if (imagePostUploaded) {
        formData.append('file', imagePostUploaded);
      }
      if (imagePostUrl) {
        formData.append('imageUrl', imagePostUrl);
      }
      
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/linkedin/posts`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${myToken}`,
          },
          body: formData,
        });
        
        if (response.ok) {
          messageApi.open({
            type: 'success',
            content: 'Publication Update',
          });
        } else {
          throw new Error('Échec update');
        }
      } catch (error: any) {
        messageApi.open({
          type: 'error',
          content: error.message || 'Erreur lors de la connexion au serveur',
        });
      }
    }
  };
  
  const onSend = () => {
    if (selectPosts === null) {
      return;
    }
    setIsModalVisible(true);
  };
  
  const handleConfirm = async (publishDate?: string) => {
    if (selectPosts === null) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append('content', contentPost);
      formData.append('linkedinPostId', selectPosts.id);
      if (publishDate) {
        formData.append('scheduleDate', publishDate);
      }
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/linkedin/posts`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${myToken}`,
        },
        body: formData,
      });
      
      if (!response.ok) {
        throw new Error(`Erreur HTTP ! statut : ${response.status}`);
      }
      
      messageApi.open({
        type: 'success',
        content: 'Publication envoyée avec succès',
      });
      fetchScheduledPosts();
      setSelectPosts(null);
      setContentPost('');
      setImagePostUrl('');
      setImagePostUploaded(null);
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error.message || 'Erreur lors de la connexion au serveur',
      });
    } finally {
      setIsModalVisible(false);
    }
  };
  
  
  return (
    <Container>
      {/* <ProgramationContainer>
          <Programmer />
        </ProgramationContainer> */}
      {contextHolder}
      <ConfirmationModal
        visible={isModalVisible}
        onConfirm={handleConfirm}
        onCancel={() => setIsModalVisible(false)}
      />
      <LeftContainer>
        <Preview setTemplatePipe={setTemplatePipe}
                 templateContent={templateContent}
                 template={template}
                 setTemplate={setTemplate}
                 image={imagePostUrl}
                 setImageUpload={setImagePostUploaded}
                 setImageUrl={setImagePostUrl}
                 contentPost={contentPost}
                 setContentPost={setContentPost}
                 OnDeletePost={OnDeletePost}
                 onUpdate={onUpdate}
                 onSend={onSend} 
                 setHtmlContent={setHtmlContent}/>
      </LeftContainer>
      <CalendarContainer>
        <StyledCalendar
          onSelect={onSelectDate}
          fullscreen={false}
          dateCellRender={dateCellRender}
        />
        <Separator />
        <PostContainer>
          <Title>Posts scheduled for the {selectedDate}</Title>
          <PostList posts={posts} selectedDate={selectedDate} OnSelectPost={OnSelectPost} />
        </PostContainer>
      </CalendarContainer>
    </Container>
  );
};

export default SchedulerPage;
