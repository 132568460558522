// src/Router.tsx
import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Settings } from './pages/Settings';
import { PrivateRoute } from './auth/PrivateRoute';
import { CreateCarousel } from './pages/CreateCarousel';
import YoutubePage from './pages/Youtube';
import CreatePost from './pages/CreatePost';
import { ArticlesPage } from './pages/Articles';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';
import TemplatePage from './pages/Template';
import { SubscriptionPage } from './pages/Subscription';
import { PersonaPage } from './pages/Persona';
import EmailConfirmed from './pages/EmailConfirmed';
import PostHistory from './pages/PostHistory';
import SchedulerPage from './pages/Scheduler';
import ContactPage from './pages/ContactPage';
import CguPage from './pages/CguPage';
import { CguRoute } from './auth/CguRoute';
import PricingPage from './pages/PricingPage';
import { TutorialPage } from './pages/Tutorial';
import ProductPage from './pages/Product';

export const RouterComponent: React.FC = () => {
  return (
    <Routes>
      <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/auth/reset-password" element={<ResetPasswordPage />} />
      <Route path="/auth/register-email-confirm" element={<EmailConfirmed />} />
      <Route path="/auth" element={<LoginPage />} />
      <Route path="/cgu" element={
        <PrivateRoute>
          <CguPage />
        </PrivateRoute>
      } />
      <Route path="/" element={
        <CguRoute>
          <PrivateRoute>
            <CreatePost />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/carousel" element={
        <CguRoute>
          <PrivateRoute>
            <CreateCarousel />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/post-history" element={
        <CguRoute>
          <PrivateRoute>
            <PostHistory />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/sources/videos" element={
        <CguRoute>
          <PrivateRoute>
            <YoutubePage />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/sources/articles" element={
        <CguRoute>
          <PrivateRoute>
            <ArticlesPage />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/settings" element={
        <CguRoute>
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/scheduler" element={
        <CguRoute>
          <PrivateRoute>
            <SchedulerPage />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/template" element={
        <CguRoute>
          <PrivateRoute>
            <TemplatePage />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/contact" element={
        <CguRoute>
          <PrivateRoute>
            <ContactPage />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/pricing" element={
        <CguRoute>
          <PrivateRoute>
            <PricingPage />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/subscribe" element={
        <CguRoute>
          <PrivateRoute>
            <SubscriptionPage />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/persona" element={
        <CguRoute>
          <PrivateRoute>
            <PersonaPage />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/product" element={
        <CguRoute>
          <PrivateRoute>
            <ProductPage />
          </PrivateRoute>
        </CguRoute>
      } />
      <Route path="/tutorial" element={
        <CguRoute>
          <PrivateRoute>
            <TutorialPage />
          </PrivateRoute>
        </CguRoute>
      } />
    </Routes>
  );
};
