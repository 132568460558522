// front/src/component/CreatePost/UploadOrTemplate/StyledComponents.tsx

import styled from 'styled-components';
import { OneToOneOutlined, PictureOutlined, PlusOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
`;

export const Result = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
`;

export const ImageImportContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ImportImageSeparation = styled.div`
  margin: 0 10px;
`;

export const TemplateContainer = styled.div`
  flex: 1;
  text-align: center;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    border-color: #028cb5;
  }
`;

export const BetaTag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #028cb5;
  color: #fff;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 0 8px 0 8px;
`;

export const TemplateContainerIcon = styled.p`
  font-size: 14px;
  margin: 10px;
  text-align: center;
  font-weight: 600;
`;

export const OneToOneOutlinedIcon = styled(OneToOneOutlined)`
  color: #028cb5;
  font-size: 48px;
`;

export const TemplateContainerText = styled.p`
  display: flex;
  flex-direction: column;
`;

export const TemplateContainerTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.88);
`;

export const TemplateContainerSubTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
`;

export const PlusOutlinedIcon = styled(PlusOutlined)`
  color: rgba(0, 0, 0, 0.45);
  font-size: 24px;
  padding: 10px;
`;

export const StyledCarousel = styled(Carousel)`
  width: 270px;

  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-arrow.slick-next,
  .slick-arrow.slick-prev {
    color: #000;
  }
`;

export const Slide = styled.div`
  position: relative;
  width: 270px;
  height: 338px;
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid #c2c2c2;
  background-color: #fff;
`;
