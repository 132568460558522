// front/src/component/CreatePost/UploadOrTemplate/TemplateModal.tsx

import React, { useEffect, useState, useContext } from 'react';
import { Modal, Spin, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { AuthContext } from '../../../auth/AuthContext';

interface TemplateModalProps {
  visible: boolean;
  onCancel: () => void;
  onSelect: (template: any) => void;
}

interface Template {
  id: string;
  name: string;
}

const TemplateModal: React.FC<TemplateModalProps> = ({ visible, onCancel, onSelect }) => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(false);
  const { myToken } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (visible) {
      fetchTemplates();
    }
  }, [visible]);

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/post-templates/user`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${myToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch templates');
      }

      const data = await response.json();
      setTemplates(data);
    } catch (error) {
      message.error('Failed to fetch templates');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title="Select a Template" visible={visible} onCancel={onCancel} footer={null}>
      {loading ? (
        <Spin />
      ) : (
        <TemplateList>
          <TemplateItem
            key="addButton"
            onClick={() => navigate('/template')}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '42px',
            }}
          >
            <PlusOutlined />
          </TemplateItem>
          {templates.map((template) => (
            <TemplateItem key={template.id} onClick={() => onSelect(template)}>
              {template.name}
            </TemplateItem>
          ))}
        </TemplateList>
      )}
    </Modal>
  );
};

export default TemplateModal;

// Styled components for TemplateModal

const TemplateList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TemplateItem = styled.div`
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  max-height: 400px;
  overflow-y: auto;

  &:hover {
    background-color: #f5f5f5;
  }
`;
