import React, { FC, ReactNode, useState } from "react";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter, useLocation } from 'react-router-dom';
import { RouterComponent } from "./Router";

import { SiderComponent } from "./SiderComponent";
import { MobileHeader } from "./MobileHeader";
import { MobileDrawer } from "./MobileDrawer";
import { AuthProvider } from "./auth/AuthContext";
import { ConfigProvider } from 'antd';
import { Logout } from "./component/buttons/Logout";
import Button from "./component/buttons/Button";


/*
 * Styles.
 */

const GlobalStyles = createGlobalStyle`
  body .ant-cascader-dropdown .ant-cascader-menu {
    min-width: auto !important;
    height: auto !important;
  }

  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-style: normal;
    line-height: normal;
    font-weight: 600;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    height: 100%;
    width: 100%;
    font-size: 1rem;
    background: #FAFBFD;
  }

  html,
  #root {
    width: 100%;
    height: 100%;
  }

  .ant-menu-dark .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #028CB5 !important;
  }
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background: #FAFBFD;

  @media (max-width: 768px) {
    height: auto;
  }

  @media (max-width: 480px) {
    height: auto;
  }
`;


const ContainerContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;


const MyContentStyled = styled.div`
  width: 100%;
  height: 100%;
  background: #FAFBFD;
  max-height: 100%;


  @media (max-width: 768px) {
    margin-top: 64px;
    margin-bottom: 34px;
    height: auto;
  }

  @media (max-width: 480px) {
    margin-top: 64px;
    margin-bottom: 34px;
    height: auto;
  }
`;

const SiderWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e4e2df;

  @media (max-width: 768px) {
    display: none;
  }
`;

// const HeaderWrapper = styled.div`
//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

const MobileHeaderWrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const hideDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Container>
      <SiderWrapper>
        <SiderComponent />
        <Logout/>
        {/* <Button variant={ButtonVariantEnum.PRIMARY} onClick={() => navigate('/subscribe') }>Subscription</Button> */}
      </SiderWrapper>
      <MobileDrawer visible={drawerVisible} onClose={hideDrawer} />
      <ContainerContent>
        <MobileHeaderWrapper>
          <MobileHeader onDrawerOpen={showDrawer} />
        </MobileHeaderWrapper>
        {/* <HeaderWrapper>
          <HeaderComponent />
        </HeaderWrapper> */}
        <MyContentStyled>
          {children}
        </MyContentStyled>
      </ContainerContent>
    </Container>
  );
};

const AppWrapper = () => {
  const location = useLocation();
  const isAuthPage = location.pathname.includes('auth');
  const isCGU = location.pathname.includes('cgu');
  const isUserCreatePage = location.pathname.includes('create-user');

  return (
    <AuthProvider>
      {(!isAuthPage && !isUserCreatePage && !isCGU ) ?
        <AppLayout>
          <RouterComponent />
        </AppLayout> : <RouterComponent />
      }
    </AuthProvider>
  );
};

export const App: FC = () => {
  return (
    <>
      <GlobalStyles />
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 6,
            colorPrimary: "#028CB5",
          },
          components: {
            Layout: {
              siderBg: "#FFFFFF",
              triggerBg: "#FFFFFF",
              bodyBg: "#FAFBFD",
            },
            Progress: {
              circleTextColor: "#fff",
              remainingColor: "#E6F3F8",
            },
            Menu: {
              darkItemBg: "transparent",
              darkItemColor: "#9C9EB1",
              darkItemHoverBg: "transparent",
              darkSubMenuItemBg: "transparent",
              darkItemHoverColor: "#363636",
              darkItemSelectedBg: "#028CB5",
              darkItemSelectedColor: "#FFFFFF",
              itemBorderRadius: 12,
              darkGroupTitleColor: "#9C9EB1",
              itemMarginBlock: 0,
              itemMarginInline: 0,
              itemBg: "transparent",
              itemSelectedBg: "#DCEAE3",
              itemSelectedColor: "#89B9A1",
              horizontalItemSelectedColor: "#028CB5",
              horizontalItemHoverColor: "#028CB5",
            },
            Input: {
              activeBorderColor: "#028CB5"
            },
            InputNumber: {
              inputFontSize: 11
            },
            Button: {
              colorPrimary: "#028CB5",
              colorPrimaryHover: "#4BACC9",
            },
          }
        }}
      >
        <BrowserRouter>
          <AppWrapper />
        </BrowserRouter>
      </ConfigProvider>
    </>
  );
};
