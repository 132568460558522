import React, { useState } from 'react';
import { List, Input } from 'antd';
import styled from 'styled-components';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TbRectangle } from "react-icons/tb";
import { FaRegCircle, FaLongArrowAltRight } from "react-icons/fa";
import { LuPencil } from "react-icons/lu";
import { BiText, BiImage } from "react-icons/bi"; // Import the necessary icons
import TextAiIcon from '../../assets/textai_black.png';

interface LeftSiderProps {
  shapes: any[];
  selectedShapeId: string | null;
  onSelectShape: (id: string) => void;
  onRenameShape: (id: string, newName: string) => void;
  onReorderShapes: (draggedIndex: number, hoverIndex: number) => void;
}

const SiderContainer = styled.div`
  padding: 10px;
  background: #fff;
  height: 100%;
  overflow-y: auto;
`;

const ListItem = styled(List.Item)<{ isSelected: boolean }>`
  background: ${({ isSelected }) => (isSelected ? '#e6f7ff' : '#fff')};
  border-left: ${({ isSelected }) => (isSelected ? '4px solid #1890ff' : '4px solid transparent')};
  padding-left: 16px;
  cursor: pointer;
  transition: background 0.3s, border-left 0.3s;

  &:hover {
    background: #f5f5f5;
  }
`;

const IconWrapper = styled.span`
  margin-right: 8px;
`;

interface DraggableListItemProps {
  shape: any;
  index: number;
  isSelected: boolean;
  onSelectShape: (id: string) => void;
  onDoubleClick: () => void;
  moveListItem: (draggedIndex: number, hoverIndex: number) => void;
  editingShapeId: string | null;
  newName: string;
  setEditingShapeId: React.Dispatch<React.SetStateAction<string | null>>;
  setNewName: React.Dispatch<React.SetStateAction<string>>;
  handleRename: () => void;
}

const getIconForShape = (type: string) => {
  switch (type) {
    case 'rect':
      return <TbRectangle size="1rem" />;
    case 'circle':
      return <FaRegCircle size="1rem" />;
    case 'arrow':
      return <FaLongArrowAltRight size="1rem" />;
    case 'scribble':
      return <LuPencil size="1rem" />;
    case 'text':
      return <BiText size="1rem" />;
    case 'image':
      return <BiImage size="1rem" />;
    case 'textAI':
      return <img src={TextAiIcon} alt="Text AI Icon" style={{ width: '1.3rem', height: '1.2rem' }} />;
    default:
      return null;
  }
};

const DraggableListItem: React.FC<DraggableListItemProps> = ({
  shape,
  index,
  isSelected,
  onSelectShape,
  onDoubleClick,
  moveListItem,
  editingShapeId,
  newName,
  setEditingShapeId,
  setNewName,
  handleRename,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'list-item',
    hover(item: { index: number }) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      moveListItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'list-item',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={() => onSelectShape(shape.id)}
      onDoubleClick={onDoubleClick}
    >
      <ListItem isSelected={isSelected}>
        <IconWrapper>{getIconForShape(shape.type)}</IconWrapper>
        {editingShapeId === shape.id ? (
          <Input
            value={newName}
            onChange={e => setNewName(e.target.value)}
            onBlur={handleRename}
            onPressEnter={handleRename}
            autoFocus
          />
        ) : (
          shape.name || shape.id
        )}
      </ListItem>
    </div>
  );
};

const LeftSider: React.FC<LeftSiderProps> = ({ shapes, selectedShapeId, onSelectShape, onRenameShape, onReorderShapes }) => {
  const [editingShapeId, setEditingShapeId] = useState<string | null>(null);
  const [newName, setNewName] = useState<string>('');

  const handleDoubleClick = (id: string, name: string) => {
    setEditingShapeId(id);
    setNewName(name);
  };

  const handleRename = () => {
    if (editingShapeId) {
      onRenameShape(editingShapeId, newName);
      setEditingShapeId(null);
    }
  };

  const moveListItem = (draggedIndex: number, hoverIndex: number) => {
    onReorderShapes(draggedIndex, hoverIndex);
  };

  return (
    <SiderContainer>
      <DndProvider backend={HTML5Backend}>
        <List
          dataSource={shapes}
          renderItem={(shape, index) => (
            <DraggableListItem
              key={shape.id}
              index={index}
              shape={shape}
              isSelected={shape.id === selectedShapeId}
              onSelectShape={onSelectShape}
              onDoubleClick={() => handleDoubleClick(shape.id, shape.name || shape.id)}
              moveListItem={moveListItem}
              editingShapeId={editingShapeId}
              newName={newName}
              setEditingShapeId={setEditingShapeId}
              setNewName={setNewName}
              handleRename={handleRename}
            />
          )}
        />
      </DndProvider>
    </SiderContainer>
  );
};

export default LeftSider;
