// src/auth/PrivateRoute.jsx
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

export const PrivateRoute = ({ children }) => { // Remplacez `component` par `children`
  const { authUser, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <div>Chargement...</div>; // ou un spinner
  }

  return authUser ? children : <Navigate to="/auth" />;
};
