// src/component/scheduler/PostList.tsx
import React from 'react';
import { ScheduledPost } from '../../types';
import styled from 'styled-components';

// Styled components for the list and list items
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; 
  overflow-y: auto; 
  width: 100%;
  padding-right: 10px;

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200, 200, 200, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e4e2df;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

  &:hover {
    background-color: #f8f8f8;
    color: rgb(2, 140, 181);
    border: 1px solid rgb(2, 140, 181);
  }
`;

const PostContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const PostTitle = styled.h4`
  margin: 0;
  color: #333;
  font-size: 16px;
`;

const PostPreview = styled.p`
  margin: 0;
  color: #666;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
`;

const NoPost = styled.p`
  margin: 0;
  color: #666;
  font-size: 12px;
  display: -webkit-box;
`;

// Props for the list item
interface PostItemProps {
  post: ScheduledPost;
  onClick: () => void;
}

// PostItem component
const PostItem: React.FC<PostItemProps> = ({ post, onClick }) => {
  // Function to get the first few words of the post
  const getPostPreview = (content: string, wordLimit: number = 6): string => {
    const words = content.split(' ');
    if (words.length <= wordLimit) return content;
    return words.slice(0, wordLimit).join(' ') + '...';
  };

  return (
    <ListItemContainer onClick={onClick}>
      <PostContent>
        <PostTitle>{getPostPreview(post.content)}</PostTitle>
        {/* You can add more details if necessary */}
      </PostContent>
    </ListItemContainer>
  );
};

// Props for the PostList component
interface PostListProps {
  posts: ScheduledPost[];
  selectedDate: string;
  OnSelectPost: (post: ScheduledPost) => void;
}

// PostList component
const PostList: React.FC<PostListProps> = ({ posts, selectedDate, OnSelectPost }) => {
  const filteredPosts = posts.filter(post => post.date === selectedDate);

  return (
    <ListContainer>
      {filteredPosts.length > 0 ? (
        filteredPosts.map(post => (
          <PostItem
            key={post.id}
            post={post}
            onClick={() => OnSelectPost(post)}
          />
        ))
      ) : (
        <NoPost>No posts scheduled for this date.</NoPost>
      )}
    </ListContainer>
  );
};

export default PostList;
