import React from 'react';
import styled from 'styled-components';
import { Input, InputNumber, Select } from 'antd';
import { generateLoremIpsum } from '../../../utils/template';

const { Option } = Select;

const SidebarContainer = styled.div`
  padding: 20px;
  background: #fff;
  height: 100%;
  overflow-y: auto;
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
`;

interface FlowPanelProps {
  selectedShape: any;
  onChange: (updates: { [key: string]: any }) => void;
}

const FlowPanel: React.FC<FlowPanelProps> = ({ selectedShape, onChange }) => {
  
  if (!selectedShape || (selectedShape.typeShape !== 'textAI' && selectedShape.typeShape !== 'imageAI')) {
    return (
      <SidebarContainer>
        Sélectionnez un champ généré (TextAI ou ImageAI) pour modifier ses propriétés.
      </SidebarContainer>
    );
  }
  
  const onChangeMaxCharacters = (value: number) => {
    const newText = generateLoremIpsum(value);
    onChange({ maxCharacters: value, text: newText });
  };
  
  
  return (
    <SidebarContainer>
      <SectionTitle>Propriétés du Champ Généré</SectionTitle>
      {selectedShape.typeShape === 'textAI' && (
        <>
          <Label>Type de Contenu</Label>
          <Select
            value={selectedShape.contentType}
            onChange={(value) => onChange({ contentType: value })}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            <Option value="title">Title</Option>
            <Option value="subtitle">Subtitle</Option>
            <Option value="summary">Summary</Option>
            <Option value="content">Content</Option>
            <Option value="bullet_points">Bullet points</Option>
            <Option value="conclusion">Conclusion</Option>
          </Select>
          
          <Label>Pré-Prompt</Label>
          <Input.TextArea
            value={selectedShape.prePrompt}
            onChange={(e) => onChange({ prePrompt: e.target.value })}
            rows={4}
            style={{ marginBottom: '10px' }}
          />
          
          <Label>Nombre maximum de caractères</Label>
          <InputNumber
            min={1}
            value={selectedShape.maxCharacters || 100}
            onChange={(value) => onChangeMaxCharacters(value)}
            style={{ width: '100%', marginBottom: '10px' }}
          />
        </>
      )}
      
      {selectedShape.typeShape === 'imageAI' && (
        <>
          <Label>Prompt d'Image</Label>
          <Input.TextArea
            value={selectedShape.prompt}
            onChange={(e) => onChange({ prompt: e.target.value })}
            rows={4}
          />
        </>
      )}
    </SidebarContainer>
  );
};

export default FlowPanel;
