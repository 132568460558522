import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { TbRectangle } from 'react-icons/tb';
import { FaLongArrowAltRight, FaRegCircle } from 'react-icons/fa';
import { LuPencil } from 'react-icons/lu';
import { GiArrowCursor } from 'react-icons/gi';
import { BiChevronDown, BiImage, BiText } from 'react-icons/bi'; // Import de l'icône DownOutlined
import { ACTIONS } from '../constants';

import TextAiIcon from '../../../assets/textai.png';
import { AuthContext } from '../../../auth/AuthContext';
import { PostTemplateModelApi } from '../models/PostTemplateModelApi';

interface ToolbarProps {
  action: string;
  setAction: (action: string) => void;
  onImageUpload: (imageUrl: string, file: File) => void;
  currentTemplate: PostTemplateModelApi | null;
  onSave: () => void;
  slides: any;
}

const ToolbarContainer = styled.div`
    display: flex;
    height: 100%;
    width: 338px;
`;

interface ButtonProps {
  active: boolean;
}

const ToolButton = styled.button<ButtonProps>`
    background-color: ${({ active }) => (active ? '#1890ff' : 'transparent')};
    color: #fff;
    border: none;
    padding: 0 10px;
    cursor: pointer;
    height: 100%;
    transition: all 0.3s ease;

    &:hover {
        background-color: ${({ active }) => (active ? '#1890ff' : '#b0b0b0')};
    }

    &:focus {
        outline: none;
    }

    &:active {
        background-color: ${({ active }) => (active ? '#1070e0' : '#a0a0a0')};
    }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #333;
    border-radius: 4px;
    overflow: hidden;
    z-index: 10;
    display: none;
`;

const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    &:hover ${DropdownMenu} {
        display: block;
    }
`;

const DropdownItem = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    color: white;
    font-size: 0.8rem;

    &:hover {
        background-color: #444;
    }

    svg {
        margin-right: 10px;
    }
`;

const HiddenFileInput = styled.input`
    display: none;
`;

const Toolbar: React.FC<ToolbarProps> = ({ action, setAction, onImageUpload, currentTemplate, onSave, slides }) => {
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const { myToken } = useContext(AuthContext);
  const [saveAfterUpload, setSaveAfterUpload] = useState(false);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  
  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     onImageUpload(file);
  //   }
  // };
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        const imageUrl = URL.createObjectURL(file); // Crée un URL local pour la prévisualisation
        onImageUpload(imageUrl, file); // Passe l'URL local et le fichier à `onImageUpload`
    }
};

  useEffect(() => {
    if (saveAfterUpload) {
      onSave();
      setSaveAfterUpload(false); // Réinitialiser après la sauvegarde
    }
  }, [slides, saveAfterUpload]);

  const handleSelectAction = (selectedAction: string) => {
    setAction(selectedAction);
  };
  
  const handleDropdownClick = () => {
    if (![ACTIONS.RECTANGLE, ACTIONS.CIRCLE, ACTIONS.ARROW, ACTIONS.SCRIBBLE].includes(action)) {
      setAction(ACTIONS.RECTANGLE);
    }
  };
  
  return (
    <ToolbarContainer>
      <ToolButton active={action === ACTIONS.SELECT} onClick={() => setAction(ACTIONS.SELECT)}>
        <GiArrowCursor size="1.2rem" />
      </ToolButton>
      
      <DropdownContainer>
        <ToolButton
          active={[ACTIONS.RECTANGLE, ACTIONS.CIRCLE, ACTIONS.ARROW, ACTIONS.SCRIBBLE].includes(action)}
          onClick={handleDropdownClick}
        >
          {action === ACTIONS.RECTANGLE && <TbRectangle size="1.2rem" />}
          {action === ACTIONS.CIRCLE && <FaRegCircle size="0.9rem" />}
          {action === ACTIONS.ARROW && <FaLongArrowAltRight size="1.2rem" />}
          {action === ACTIONS.SCRIBBLE && <LuPencil size="0.9rem" />}
          {![ACTIONS.RECTANGLE, ACTIONS.CIRCLE, ACTIONS.ARROW, ACTIONS.SCRIBBLE].includes(action) &&
            <TbRectangle size="1.2rem" />} {/* Default icon */}
          <BiChevronDown size="1.2rem" style={{ marginLeft: '3px' }} /> {/* Icône DownOutlined */}
        </ToolButton>
        <DropdownMenu>
          <DropdownItem onClick={() => handleSelectAction(ACTIONS.RECTANGLE)}>
            <TbRectangle size="1.2rem" /> Rectangle
          </DropdownItem>
          <DropdownItem onClick={() => handleSelectAction(ACTIONS.CIRCLE)}>
            <FaRegCircle size="0.9rem" /> Circle
          </DropdownItem>
          <DropdownItem onClick={() => handleSelectAction(ACTIONS.ARROW)}>
            <FaLongArrowAltRight size="1.2rem" /> Arrow
          </DropdownItem>
          <DropdownItem onClick={() => handleSelectAction(ACTIONS.SCRIBBLE)}>
            <LuPencil size="0.9rem" /> Scribble
          </DropdownItem>
        </DropdownMenu>
      </DropdownContainer>
      
      <ToolButton active={action === ACTIONS.TEXT} onClick={() => setAction(ACTIONS.TEXT)}>
        <BiText size="1.2rem" />
      </ToolButton>
      
      <ToolButton active={false} onClick={handleImageClick}>
        <BiImage size="1.2rem" />
      </ToolButton>
      
      <ToolButton active={action === ACTIONS.TEXTAI} onClick={() => setAction(ACTIONS.TEXTAI)}>
        <img src={TextAiIcon} alt="Text AI Icon" style={{ width: '1.3rem', height: '1.2rem' }} />
      </ToolButton>
      {/* 
      <ToolButton active={action === ACTIONS.IMAGEAI} onClick={() => setAction(ACTIONS.IMAGEAI)}>
        <img src={ImageAiIcon} alt="Image AI Icon" style={{ width: '1.4rem', height: '1rem' }} />
      </ToolButton> */}
      
      <HiddenFileInput
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      
      {/* <ToolButton active={false} onClick={handleExport}>
        <IoMdDownload size="1rem" />
      </ToolButton> */}
    
    </ToolbarContainer>
  );
};

export default Toolbar;
