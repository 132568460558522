import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';
import { Tabs } from '../component/Settings/Tabs';
import { Profile } from '../component/Settings/Profile';
import { Subscription } from '../component/Settings/Subscription';


const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    padding: 50px 50px;

    @media (max-width: 768px) {
        padding: 20px 20px;
    }
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;


export const Settings: React.FC = () => {
  const { authUser, myToken, checkUser } = useContext(AuthContext);
  const [selectTab, setSelectTab] = useState<string>('Profile');
  const navigate = useNavigate();

  const handleSubscriptionClick = () => {
    if (authUser.subscriptionPlan === 'FREE') {
      navigate('/pricing'); // Redirection vers une autre page
    } else {
      setSelectTab('Subscription');
    }
  };
  
  const renderTabContent = () => {
    switch (selectTab) {
      case 'Profile':
        return <Profile authUser={authUser} />;
      case 'Subscription':
        return <Subscription authUser={authUser} />;
      // case 'Secrets':
      //   return <Secrets authUser={authUser} myToken={myToken} checkUser={checkUser}/>;
      default:
        return null;
    }
  };
  
  return (
    <Container>
      <Tabs selectTab={selectTab} setSelectTab={(tab) => (tab === 'Subscription' ? handleSubscriptionClick() : setSelectTab(tab))} tabList={['Profile', 'Subscription']} />
      <Line />
      {renderTabContent()}
    </Container>
  );
};
