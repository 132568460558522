import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ArticleType, FormValues, Video } from '../../types';
import SubjectComponent from './SubjectComponent';
import StyleComponent from './StyleComponent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
  padding: 16px 16px;
`;

interface IdeaProps {
  onGenerate: () => void;
  onFormChange: (changedValues: Partial<FormValues>) => void;
  formValues: FormValues;
  loading: boolean;
  setLoading: (b: boolean) => void;
  setImage: (image: any) => void;
  initialSelectedVideo: Video | null;
  initialSelectedArticle: ArticleType | null;
  refIdea: React.RefObject<HTMLDivElement>;
  refStyle: React.RefObject<HTMLDivElement>;
}

const Idea: React.FC<IdeaProps> = ({ onGenerate, onFormChange, formValues, loading, setLoading, setImage, initialSelectedVideo, initialSelectedArticle, refIdea, refStyle }) => {
  const handleSubjectChange = useCallback((changedValues: Partial<FormValues>) => {
    onFormChange(changedValues);
  }, [formValues, onFormChange]);

  const handleStyleChange = useCallback((changedValues: Partial<FormValues>) => {
    onFormChange(changedValues);
  }, [formValues, onFormChange]);

  return (
    <Container>
      <SubjectComponent ref={refIdea} formValues={formValues} onChange={handleSubjectChange} setLoading={setLoading} setImage={setImage} initialSelectedVideo={initialSelectedVideo} initialSelectedArticle={initialSelectedArticle} />
      <StyleComponent ref={refStyle} onChange={handleStyleChange} />
    </Container>
  );
};

export default React.memo(Idea);
