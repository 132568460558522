import axios from 'axios';
import { ProductModelApi, SubscriptionInvoiceApi, SubscriptionModelApi } from '../model/SubscriptionModelApi';

export class SubscriptionService {
  private static SUBSCRIPTION_BASE_URL = `${process.env.REACT_APP_API_URL}/subscription`;
  
  static async getUserSubscription(token: string): Promise<SubscriptionModelApi | null> {
    try {
      const response = await axios.get<SubscriptionModelApi>(`${this.SUBSCRIPTION_BASE_URL}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user subscription:', error);
      throw error;
    }
  }
  
  static async getUserSubscriptionInvoices(token: string): Promise<SubscriptionInvoiceApi[]> {
    try {
      const response = await axios.get<{
        invoices: SubscriptionInvoiceApi[]
      }>(`${this.SUBSCRIPTION_BASE_URL}/invoices`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data.invoices;
    } catch (error) {
      console.error('Error fetching user subscription:', error);
      throw error;
    }
  }
  
  static async getSubscriptionInvoiceUrl(token: string, invoiceId: string): Promise<string> {
    try {
      const response = await axios.get<string>(`${this.SUBSCRIPTION_BASE_URL}/invoice-download-url`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          subscriptionInvoiceId: invoiceId,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user subscription:', error);
      throw error;
    }
  }
  
  static async getCheckoutUrl(token: string, variantId: number): Promise<string> {
    try {
      const response = await axios.get<string>(`${this.SUBSCRIPTION_BASE_URL}/checkout`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          variantId,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching checkout URL:', error);
      throw error;
    }
  }
  
  static async getProductsSubscription(token: string): Promise<ProductModelApi[]> {
    try {
      const response = await axios.get<ProductModelApi[]>(`${this.SUBSCRIPTION_BASE_URL}/products`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error while fetching products and variants :', error);
      throw new Error();
    }
  }
  
  static async cancelSubscription(token: string): Promise<boolean> {
    try {
      const response = await axios.patch<boolean>(`${this.SUBSCRIPTION_BASE_URL}/cancel`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error canceling subscription:', error);
      throw error;
    }
  };
  
  static async changeSubscriptionPlan(variantId: number, token: string): Promise<boolean> {
    try {
      const response = await axios.patch<boolean>(`${this.SUBSCRIPTION_BASE_URL}/change-plan`, { variantId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error changing subscription plan:', error);
      throw error;
    }
  };
  
  static async resumeSubscription(token: string): Promise<boolean> {
    try {
      const response = await axios.patch<boolean>(`${this.SUBSCRIPTION_BASE_URL}/resume`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error resuming subscription:', error);
      throw error;
    }
  };
  
  static async getUpdatePaymentMethodUrl(token: string): Promise<string> {
    try {
      const response = await axios.get<string>(`${this.SUBSCRIPTION_BASE_URL}/change-payment-method-url`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error resuming subscription:', error);
      throw error;
    }
  };
}