import React, { useState, useEffect, useContext } from 'react';
import { Button, Card } from 'antd';
import styled from 'styled-components';
import { Product } from '../../types';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { AuthContext } from '../../auth/AuthContext';
import ProductModal from './ProductModal';

const PageContainer = styled.div`
  padding: 20px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const ProductCard = styled(Card)`
  width: 300px;
  flex: 0 0 auto;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1; 
`;

const ContentParagraph = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Adjust the number of lines as needed */
  -webkit-box-orient: vertical;
`;

const AddCard = styled.div`
  border: 1px solid rgb(240, 240, 240);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 358px;
  background-color: #fff;
  font-size: 36px;
  color: #c3c3c3;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

  &:hover {
    background-color: #f8f8f8;
    color: rgb(2, 140, 181);
    border: 1px solid rgb(2, 140, 181);
  }
`;

const ProductList: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<Product | null>(null);
  const { myToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/company-offers/user`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
          },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [myToken]);

  const handleCreateOrEdit = async (product: Product) => {
    try {
      if (currentProduct) {
        // Mise à jour du product existant
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/company-offers/${product.id}`, product, {
          headers: {
            Authorization: `Bearer ${myToken}`,
          },
        });
        setProducts(products.map(p => (p.id === product.id ? response.data : p)));
      } else {
        // Création d'un nouveau product
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/company-offers`, product, {
          headers: {
            Authorization: `Bearer ${myToken}`,
          },
        });
        setProducts([...products, response.data]);
      }
      setModalVisible(false);
      setCurrentProduct(null);
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };

  const handleEditClick = (product: Product) => {
    setCurrentProduct(product);
    setModalVisible(true);
  };

  const handleDeleteClick = async (productId: string) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/company-offers/${productId}`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
        },
      });
      setProducts(products.filter((p: any) => p.id !== productId));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  return (
    <PageContainer>
      <CardContainer>
        <AddCard onClick={() => setModalVisible(true)}>
          <PlusOutlined />
        </AddCard>
        {products.map(product => (
          <ProductCard
            key={product.id}
            title={product.title}
            actions={[
              <Button onClick={() => handleEditClick(product)}>Edit</Button>,
              <Button danger onClick={() => handleDeleteClick(product.id!)}>
                Delete
              </Button>
            ]}
          >
            <ContentContainer>
              <ContentParagraph>
                <strong>Description:</strong> {product.description}
              </ContentParagraph>
            </ContentContainer>
          </ProductCard>
        ))}
      </CardContainer>
      <ProductModal
        visible={modalVisible}
        onCreateOrEdit={handleCreateOrEdit}
        product={currentProduct}
        onCancel={() => {
          setModalVisible(false);
          setCurrentProduct(null);
        }}
      />
    </PageContainer>
  );
};

export default ProductList;
