// src/pages/CguPage.jsx
import { useContext, useState } from 'react';
import { AuthContext } from '../auth/AuthContext';
import styled from 'styled-components';
import logoImage from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import type { CheckboxProps } from 'antd';
import { Checkbox } from 'antd';
import Button, { ButtonVariantEnum } from '../component/buttons/Button';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #E6F4F8;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px 28px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.02);
`;

const LogoStyled = styled.div`
    height: 64px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`;

const Logo = styled.div`
    width: 42px;
    height: 42px;
    background: url(${logoImage}) no-repeat center center;
    background-size: contain;
`;

const Title = styled.div`
    font-size: 24px;
    color: #636D79;
    font-weight: 700;
    letter-spacing: 2px;
`;

const ActionContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const CguPage = () => {
  const { setCguAccepted, myToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  
  const onChange: CheckboxProps['onChange'] = (e) => {
    setIsChecked(e.target.checked);
  };
  
  const handleAccept = async () => {
    if (isChecked) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/me/accept-terms-and-conditions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${myToken}`,
            'Accept': 'application/json',
          },
        });
        
        if (response.ok) {
          setCguAccepted(true);
          navigate('/');
        } else {
          console.error('Failed to accept terms:', response.statusText);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  };
  
  
  return (
    <Container>
      <Card>
        <LogoStyled>
          <Logo />
          <Title>PostPilot</Title>
        </LogoStyled>
        <ActionContainer>
          <Checkbox onChange={onChange}>
            I have read and accept the{' '}
            <a href="https://postpilot.in/fr/conditions" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
          </Checkbox>
          <Button
            style={{ width: 100 }}
            variant={ButtonVariantEnum.PRIMARY}
            onClick={handleAccept}
            disabled={!isChecked}
          >
            Accept
          </Button>
        </ActionContainer>
      </Card>
    </Container>
  );
};

export default CguPage;
