import React from 'react';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const InputContainer = styled.div<{ emailExists?: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    position: relative;
    background-color: "#ffe6e6";
    border: ${(p) => (p.emailExists ? '1px solid #FF6B6B' : 'none')};
    border-radius: 10px;
`;

const CustomInput = styled.input`
    padding: 16px 16px 15px 16px;
    border: none;
    border-radius: 10px;
    width: 100%;
    background: #f5f5f5;
    color: #2c2c2c;
    font-size: 13.333px;
    font-weight: 600;
    line-height: normal;

    &::placeholder {
        color: #2c2c2c;
    }

    &:focus,
    &:active {
        outline: none;
    }
`;

const Container = styled.div`
    width: 100%;
`;

const Asterisk = styled.span`
    color: red;
    position: absolute;
    left: 7px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
`;

const InfoCircleOutlinedStyled = styled(InfoCircleOutlined)`
    font-size: 20px;
    color: #1890ff;
    cursor: pointer;

    &:hover {
        color: #40a9ff;
    }
`;

const ErrorMessage = styled.div`
    color: #FF6B6B;
    font-size: 12px;
    margin-top: 5px;
    padding-left: 5px;
`;

interface InputFieldProps {
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  emailExists?: boolean;
  required?: boolean;
  tooltipInfo?: React.ReactNode;
  errorMessage?: string;
}

const InputField: React.FC<InputFieldProps> = ({
                                                 type,
                                                 placeholder,
                                                 value,
                                                 onChange,
                                                 emailExists = false,
                                                 required = false,
                                                 tooltipInfo,
                                                 errorMessage,
                                               }) => {
  return (
    <Container>
      <InputContainer emailExists={emailExists}>
        {required && <Asterisk>*</Asterisk>}
        <CustomInput
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        {tooltipInfo &&
          <Tooltip placement="top" title={tooltipInfo} arrow={true} mouseEnterDelay={0.2}>
            <InfoCircleOutlinedStyled />
          </Tooltip>}
      </InputContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};

export default InputField;
