// src/hooks/useShapes.ts

import React, { useState, useRef, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Konva from 'konva';
import { AuthContext } from '../auth/AuthContext';
import { ACTIONS } from '../component/TemplateEditor/constants';

interface Shape {
  id: string;
  x: number;
  y: number;
  width?: number;
  height?: number;
  rotation: number;
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  shadowBlur?: number;
  cornerRadius?: number;
  opacity?: number;
  typeShape: string;
  visible?: boolean;
  isGradientFill?: boolean;
  gradientType?: string;
  fillLinearGradientStartPoint?: any;
  fillLinearGradientEndPoint?: any;
  fillLinearGradientColorStops?: any[];
  fillRadialGradientStartPoint?: any;
  fillRadialGradientStartRadius?: number;
  fillRadialGradientEndPoint?: any;
  fillRadialGradientEndRadius?: number;
  fillRadialGradientColorStops?: any[];
  name?: string;

  prePrompt?: string;
  contentType?: string;
  maxCharacters?: number;
  prompt?: string;

  // Text-specific properties
  text?: string;
  fontSize?: number;
  fontFamily?: string;
  fontStyle?: string;
  align?: string;
  lineHeight?: number;
  wrap?: string;

  imageSrc?: string;
  image?: any;
  isSavedToBackend?: boolean;
}


const useShapes = (
  slides: any[][],
  selectedSlideIndex: number,
  setSlides: React.Dispatch<React.SetStateAction<any[][]>>,
  action: string, 
  setAction: React.Dispatch<React.SetStateAction<string>>,
  stageRef: React.RefObject<any>
) => {
  const [selectedShape, setSelectedShape] = useState<any>(null);
  const [selectedKonvaShape, setSelectedKonvaShape] = useState<any>(null);
  const [pendingDeletes, setPendingDeletes] = useState<string[]>([]);
  const transformerRef = useRef<any>(null);
  const { myToken } = useContext(AuthContext);

  const handleNewShape = async (newShape: any) => {
    setSlides((prevSlides: any[][]) => {
      const newSlides = [...prevSlides];
      newSlides[selectedSlideIndex] = [...newSlides[selectedSlideIndex], newShape];
      return newSlides;
    });
  };

  const handleShapeChange = (updates: { [key: string]: any }) => {
    if (selectedShape) {
      const updatedShape = { ...selectedShape, ...updates };
      setSelectedShape(updatedShape);

      if (selectedKonvaShape) {
        if (updates.text !== undefined) {
          // Reset the height to allow Konva to recalculate it
          selectedKonvaShape.height(null);
        }
        selectedKonvaShape.setAttrs(updates);
        selectedKonvaShape.getLayer().batchDraw();
      }

      setSlides((prevSlides: any[][]) => {
        const newSlides = [...prevSlides];
        newSlides[selectedSlideIndex] = newSlides[selectedSlideIndex].map((shape: any) =>
          shape.id === selectedShape.id ? { ...shape, ...updates } : shape
        );
        return newSlides;
      });
    }
  };


  const handleDeleteSelectedShape = () => {
    if (selectedShape) {
      if (selectedShape.type === 'image' && selectedShape.imageSrc) {
        setPendingDeletes((prevDeletes) => [...prevDeletes, selectedShape.imageSrc]);
      }

      setSlides((prevSlides: any[][]) => {
        const newSlides = [...prevSlides];
        newSlides[selectedSlideIndex] = newSlides[selectedSlideIndex].filter(
          (shape: any) => shape.id !== selectedShape.id
        );
        return newSlides;
      });
      setSelectedShape(null);
      setSelectedKonvaShape(null);
      if (transformerRef.current) {
        transformerRef.current.nodes([]);
        transformerRef.current.getLayer().batchDraw();
      }
    }
  };

  const executePendingDeletes = async () => {
    for (const url of pendingDeletes) {
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${myToken}`,
          },
        });

        if (!response.ok) {
          console.error(`Erreur lors de la suppression de l'image: ${url}`);
        }
      } catch (error) {
        console.error('Erreur lors de l\'envoi de la requête de suppression:', error);
      }
    }
    setPendingDeletes([]);
  };

  const handleRenameShape = (id: string, newName: string) => {
    setSlides((prevSlides: any[][]) => {
      const newSlides = [...prevSlides];
      newSlides[selectedSlideIndex] = newSlides[selectedSlideIndex].map((shape: any) =>
        shape.id === id ? { ...shape, name: newName } : shape
      );
      return newSlides;
    });
  };

  const selectAndTransformShape = (konvaShape: any) => {
    if (!konvaShape) return;

    setSelectedKonvaShape(konvaShape);

    if (transformerRef.current) {
      transformerRef.current.nodes([konvaShape]);
      transformerRef.current.getLayer().batchDraw();
    }

    console.log("konvaShape", konvaShape);

    const shape: Shape = {
      id: konvaShape.id(),
      x: konvaShape.x(),
      y: konvaShape.y(),
      width: konvaShape.width(),
      height: konvaShape.height(),
      rotation: konvaShape.rotation(),
      fill: konvaShape.fill(),
      stroke: konvaShape.stroke(),
      strokeWidth: konvaShape.strokeWidth(),
      shadowBlur: konvaShape.shadowBlur(),
      cornerRadius: konvaShape.cornerRadius ? konvaShape.cornerRadius() : 0,
      opacity: konvaShape.opacity(),
      typeShape: konvaShape.attrs.type,
      visible: konvaShape.visible(),
      isGradientFill: konvaShape.getAttr('isGradientFill') || false,
      gradientType: konvaShape.getAttr('gradientType') || 'linear',
      fillLinearGradientStartPoint: konvaShape.getAttr('fillLinearGradientStartPoint') || { x: 0, y: 0 },
      fillLinearGradientEndPoint: konvaShape.getAttr('fillLinearGradientEndPoint') || { x: 0, y: 0 },
      fillLinearGradientColorStops: konvaShape.getAttr('fillLinearGradientColorStops') || [],
      fillRadialGradientStartPoint: konvaShape.getAttr('fillRadialGradientStartPoint') || { x: 0, y: 0 },
      fillRadialGradientStartRadius: konvaShape.getAttr('fillRadialGradientStartRadius') || 0,
      fillRadialGradientEndPoint: konvaShape.getAttr('fillRadialGradientEndPoint') || { x: 0, y: 0 },
      fillRadialGradientEndRadius: konvaShape.getAttr('fillRadialGradientEndRadius') || 70,
      fillRadialGradientColorStops: konvaShape.getAttr('fillRadialGradientColorStops') || [],
      name: konvaShape.name(),

      prePrompt: konvaShape.getAttr('prePrompt') || '',
      contentType: konvaShape.getAttr('contentType') || '',
      maxCharacters: konvaShape.getAttr('maxCharacters') || 100,
      prompt: konvaShape.getAttr('prompt') || '',
    };

    if (konvaShape instanceof Konva.Text) {
      shape.text = konvaShape.text();
      shape.fontSize = konvaShape.fontSize();
      shape.fontFamily = konvaShape.fontFamily();
      shape.fontStyle = konvaShape.fontStyle();
      shape.align = konvaShape.align();
      shape.lineHeight = konvaShape.lineHeight();
      shape.wrap = konvaShape.wrap();
      // Include any other text properties you need
    }

    if (konvaShape instanceof Konva.Image) {
      shape.imageSrc = konvaShape.getAttr("imageSrc");
      shape.image = konvaShape.getAttr("image");
      shape.isSavedToBackend = konvaShape.getAttr("isSavedToBackend");
    }

    setSelectedShape(shape);
  };

  const handleShapeSelect = (shapeOrId: any) => {
    if (typeof shapeOrId === 'string') {
      const foundShape = slides[selectedSlideIndex].find((shape: any) => shape.id === shapeOrId);
      const konvaShape = stageRef.current.findOne(`#${shapeOrId}`);
      if (konvaShape) {
        selectAndTransformShape(konvaShape);
      }
    } else {
      const konvaShape = shapeOrId;
      selectAndTransformShape(konvaShape);
    }
    if (shapeOrId === null) {
      setSelectedShape(null);
      setSelectedKonvaShape(null);
    }
  };

  const handleReorderShapes = (draggedIndex: number, hoverIndex: number) => {
    const reorderedShapes = [...slides[selectedSlideIndex]];
    const [draggedShape] = reorderedShapes.splice(draggedIndex, 1);
    reorderedShapes.splice(hoverIndex, 0, draggedShape);
    setSlides((prevSlides: any[][]) => {
      const newSlides = [...prevSlides];
      newSlides[selectedSlideIndex] = reorderedShapes;
      return newSlides;
    });
  };

  // const handleImageUpload = (file: File) => {
  //   const shapeIndex = slides[selectedSlideIndex].length + 1;
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     const imageObj = new window.Image();
  //     imageObj.onload = () => {
  //       const newImage = {
  //         id: uuidv4(),
  //         name: `Image ${shapeIndex}`,
  //         type: 'image',
  //         x: stageRef.current.width() / 2 - imageObj.width / 2,
  //         y: stageRef.current.height() / 2 - imageObj.height / 2,
  //         image: imageObj,
  //         imageSrc: reader.result as string,
  //         width: imageObj.width,
  //         height: imageObj.height,
  //       };
  //       handleNewShape(newImage);
  //     };
  //     imageObj.src = reader.result as string;
  //   };
  //   reader.readAsDataURL(file);
  // };

  const handleImageUpload = (imageUrl: string, file: File) => {
    const shapeIndex = slides[selectedSlideIndex].length + 1;
    const imageObj = new window.Image();
    imageObj.src = imageUrl;

    imageObj.onload = () => {
      let width = imageObj.width;
      let height = imageObj.height;

      const maxHeight = 200;
      if (height > maxHeight) {
        const scaleFactor = maxHeight / height;
        height = maxHeight;
        width = width * scaleFactor;
      }

      const x = (stageRef.current.width() - width) / 2;
      const y = (stageRef.current.height() - height) / 2;

      const newImage = {
        id: uuidv4(),
        name: `Image ${shapeIndex}`,
        type: 'image',
        x,
        y,
        image: imageObj,
        imageFile: file,           // Stocke le fichier pour l'upload ultérieur
        imageSrc: imageUrl,         // Utilise l'URL local pour la prévisualisation
        isSavedToBackend: false,    // Marque comme non sauvegardé
        width,
        height,
      };

      handleNewShape(newImage); // Ajoute l'image au canevas sans upload
    };

    imageObj.onerror = () => {
      console.error("Erreur lors du chargement de l'image");
    };
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isFormElement =
        (event.target as HTMLElement).tagName === 'INPUT' ||
        (event.target as HTMLElement).tagName === 'TEXTAREA' ||
        (event.target as HTMLElement).isContentEditable;

      // Suppression de la forme avec Delete ou Backspace
      if (!isFormElement && (event.key === 'Delete' || event.key === 'Backspace')) {
        handleDeleteSelectedShape();
      }

      // Désélectionner ou passer en mode SELECT avec Escape
      if (event.key === 'Escape') {
        if (selectedShape) {
          // Si une forme est sélectionnée, la désélectionner
          setSelectedShape(null);
          setSelectedKonvaShape(null);

          if (transformerRef.current) {
            transformerRef.current.nodes([]);
            transformerRef.current.getLayer().batchDraw();
          }
        } else if (action !== ACTIONS.SELECT) {
          // Si aucune forme n'est sélectionnée et l'action actuelle n'est pas SELECT, changer l'action
          setAction(ACTIONS.SELECT);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedShape, action, handleDeleteSelectedShape, setAction]);


  return {
    selectedShape,
    setSelectedShape,
    selectedKonvaShape,
    setSelectedKonvaShape,
    transformerRef,
    handleNewShape,
    handleShapeChange,
    handleDeleteSelectedShape,
    handleShapeSelect,
    handleRenameShape,
    handleReorderShapes,
    handleImageUpload,
    executePendingDeletes,
  };
};

export default useShapes;
