import React, { useState, useEffect, ChangeEvent } from 'react';
import { Modal, Input, Select, Tabs, Slider, Typography, List, Button } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Product } from '../../types';

const { Option } = Select;
const { TabPane } = Tabs;
const { Title } = Typography;

interface ProductModalProps {
  visible: boolean;
  onCreateOrEdit: (product: Product) => void;
  product: Product | null;
  onCancel: () => void;
}

const FormItem = styled.div`
  margin-bottom: 20px;
`;

const initialFormState: Product = {
  id: undefined, 
  title: '',
  description: '',
};

const ProductModal: React.FC<ProductModalProps> = ({ visible, onCreateOrEdit, product, onCancel }) => {
  const [form, setForm] = useState<Product>(initialFormState);

  useEffect(() => {
    if (product) {
      setForm(product);
    } else {
      setForm(initialFormState);
    }
  }, [product]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSelectChange = (value: any, field: keyof Product) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleNumberChange = (value: number | null, field: keyof Product) => {
    if (value !== null) {
      setForm({
        ...form,
        [field]: value,
      });
    }
  };



  const handleSubmit = () => {
    // Validation du formulaire si nécessaire
    onCreateOrEdit(form);
  };

  return (
    <Modal
      title={product ? 'Edit Product' : 'Create a new Product'}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      width={800}
    >
      <Tabs defaultActiveKey="whoami">
        <TabPane tab="Who I Am" key="whoami">
          <FormItem>
            <Input
              name="title"
              value={form.title}
              onChange={handleInputChange}
              placeholder={'title'}
              maxLength={200}
            />
          </FormItem>
          <FormItem>
            <Input.TextArea
              name="description"
              value={form.description}
              onChange={handleInputChange}
              placeholder="Description"
              autoSize={{ minRows: 2, maxRows: 6 }}
              rows={4}
              maxLength={8000}
            />
          </FormItem>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ProductModal;
